<template>
  <div class="mt-2">
    <div
      class="content-card-table"
      :class="{ 'content-card-table-resize': returnTableFiltre }"
    >
      <!-- TABLE CONFIGURATION PAR SOCIETE-->
      <v-data-table
        v-model="selectedTable"
        :headers="fields"
        :items="getAllConfMensuel"
        class="table-setting-config"
        :class="{
          'table-setting-config-resize': returnTableFiltre
        }"
        hide-default-footer
        show-expand
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :calculate-widths="false"
        :fixed-header="true"
        :items-per-page="perPage"
        hide-action
        :no-data-text="
          getConfLoading
            ? 'Chargement en cours ...'
            : `Il n'y a aucun enregistrement à afficher`
        "
      >
        <template v-slot:[`item.data-table-expand`]="{ item }">
          <td class="custom-cell">
            <button class="btn btn-anomalie" @click="getDataForOneRow(item)">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                color="#704ad1"
                title="Afficher Facture(s)"
                v-if="item.showingDetails == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                color="#704ad1"
                title="Masquer Facture(s)"
                v-if="item.showingDetails == true"
              />
            </button>
          </td>
        </template>
        <template v-slot:[`item.nom_service_bien`]="{ item }">
          <td class="custom-cell">{{ item.vendeur }}</td>
        </template>
        <template v-slot:[`item.name`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.vendeur`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.reference`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.montant`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.tva`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.date_debut`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.date_fin`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.jour_creation`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.frequence`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.description`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.objet`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <!-- EXPANDED TABLE -->
        <template #expanded-item="{ headers,item }">
          <td
            class="td-expanded"
            :colspan="headers.length"
            v-if="
              item &&
                item.confs &&
                item.confs.length > 0 &&
                item.showingDetails == true
            "
          >
            <table :items="item.confs" class="table-expanded">
              <tbody>
                <tr
                  v-for="(data, index) in item.confs"
                  :key="data + 'config' + index"
                >
                  <!-- VIDE -->
                  <td
                    class="td-expanded"
                    style="width:40px  !important ; min-width:40px !important"
                  >
                    -
                  </td>
                  <!--NOM SERVICE BIEN-->
                  <td class="td-expanded">
                    {{ data.nom_service_bien }}
                  </td>
                  <!-- SOCIETE -->
                  <td class="td-expanded">
                    {{ data.vendeur }}
                  </td>
                  <!-- CLIENT -->
                  <td class="td-expanded">
                    {{ data.acheteur }}
                  </td>
                  <!--REFRENCE-->
                  <td class="td-expanded">
                    {{ data.reference }}
                  </td>
                  <!--MONTANT-->
                  <td class="td-expanded">{{ data.montant }} €</td>
                  <!--TVA-->
                  <td class="td-expanded">{{ data.tva }} %</td>
                  <!--DATE DEBUT-->
                  <td class="td-expanded">
                    {{ data.date_debut | formateDate }}
                  </td>
                  <!--DATE FIN-->
                  <td class="td-expanded">
                    {{ data.date_fin | formateDate }}
                  </td>
                  <!--JOUR CREATION-->
                  <td class="td-expanded">
                    {{ data.jour_creation | formateJourCreation }}
                  </td>
                  <!--FREQUENCE-->
                  <td class="td-expanded">
                    {{ data.frequence }}
                  </td>
                  <!--DESCRIPTION-->
                  <td class="td-expanded">
                    {{ data.description ? data.description : '-' }}
                  </td>
                  <!--OBJET-->
                  <td class="td-expanded">
                    {{ data.objet ? data.objet : '-' }}
                  </td>

                  <td class="td-expanded">
                    <actionConfiguration :item="data" type="bien" />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Gestion-des-produits',
  data() {
    return {
      singleExpand: false,
      expanded: [],
      selectedTable: [],
      fields: [
        {
          value: 'data-table-expand',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important'
        },
        {
          value: 'nom_service_bien',
          text: 'Nom Bien',
          sortable: false
        },
        {
          value: 'name',
          text: 'Societé',
          sortable: false
        },
        {
          value: 'vendeur',
          text: 'Client',
          sortable: false
        },
        {
          value: 'reference',
          text: 'Référence',
          sortable: false
        },
        {
          value: 'montant',
          text: 'Montant',
          sortable: false
        },
        {
          value: 'tva',
          text: 'TVA',
          sortable: false
        },
        {
          value: 'date_debut',
          text: 'Date début',
          sortable: false
        },
        {
          value: 'date_fin',
          text: 'Date fin',
          sortable: false
        },
        {
          value: 'jour_creation',
          text: 'Jour création',
          sortable: false
        },
        {
          value: 'frequence',
          text: 'Fréquence',
          sortable: false
        },

        {
          value: 'description',
          text: 'Description',
          sortable: false
        },
        {
          value: 'objet',
          text: 'Objet',
          sortable: false
        },
        {
          value: 'action',
          text: 'Action',
          sortable: false
        }
      ],
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    },
    formateJourCreation: value => {
      if (value == '' || value == null) {
        return 'Date du jour';
      } else if (value == -1) {
        return 'Fin du mois';
      } else {
        return value;
      }
    }
  },
  methods: {
    ...mapActions(['fetchAllMensuel', 'updateConfigurationComptaRh']),
    getDataForOneRow(item) {
      item.showingDetails = !item.showingDetails;
      if (item.showingDetails == true) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      }
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },

    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    handleFilter() {
      this.fetchAllMensuel({
        per_page: this.perPage,
        page: this.page,
        grouped: 'bien',
        vendeur_ids: this.getFiltre.vendeur,
        acheteur_ids: this.getFiltre.acheteur,
        period: this.getFiltre.period,
        type_produit: this.getFiltre.type_produit,
        service_bien: this.getFiltre.service_bien
      });
    }
  },
  computed: {
    ...mapGetters([
      'getConfLoading',
      'getAllConfMensuel',
      'getTotalRowConfComptaRh',
      'checkPermission',
      'getFiltre'
    ]),
    returnTableFiltre() {
      return (
        this.getFiltre.vendeur.length ||
        this.getFiltre.acheteur.length ||
        (this.getFiltre.service_bien.length && this.getFiltre.type_produit)
      );
    },
    totalPages() {
      if (this.getTotalRowConfComptaRh) {
        return Math.ceil(this.getTotalRowConfComptaRh / this.perPage);
      }
      return this.getTotalRowConfComptaRh;
    }
  },
  components: {
    actionConfiguration: () => import('./actionConfiguration.vue')
  },
  watch: {
    'getFiltre.vendeur'() {
      this.handleFilter();
    },
    'getFiltre.acheteur'() {
      this.handleFilter();
    },
    'getFiltre.period'() {
      this.handleFilter();
    },
    'getFiltre.type_produit'() {
      this.handleFilter();
    },
    'getFiltre.service_bien'() {
      this.handleFilter();
    },
    deep: true
  },
  async mounted() {
    this.handleFilter();
  }
};
</script>
<style lang="scss" scoped>
.style-box-setting .box-content .content-card-table {
  height: calc(100vh - 279px);
}
.content-card-table-resize {
  height: calc(100vh - 337px) !important;
}
</style>
