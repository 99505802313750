<template>
  <div class="visibility-interface style-box-setting">
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GUTHAOP')"
                @click.prevent="addProfileModal = true"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter un profil</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="profile-content">
      <div class="permission-hearder">
        <div class="title">Profiles</div>

        <div v-if="loadingAdd" class="chargement-message-text ml-2">
          Chargement en cours
        </div>
        <div class="fix-height-slide">
          <v-list class="list-category">
            <v-list-item-group>
              <v-list-item
                button
                v-for="role in roles"
                :key="role.id"
                class="custom-list-group custom-list-group-vue"
              >
                <v-list-item-content>
                  <v-row class="align-center">
                    <v-col cols="2">
                      <font-awesome-icon
                        icon="eye"
                        @click="blockedUpdate(role, role.id)"
                        v-if="
                          role.blocked == false && checkPermission('GPRHTBDP')
                        "
                      />
                      <font-awesome-icon
                        icon="eye-slash"
                        @click="deblockedUpdate(role, role.id)"
                        v-if="role.blocked && checkPermission('GPRHTBDP')"
                      />
                    </v-col>
                    <v-col cols="8" @click.prevent="handleClickRole(role)">
                      <span class="capitalize"> {{ role.name }}</span>
                    </v-col>
                    <v-col cols="2">
                      <font-awesome-icon
                        class="color-cdg"
                        icon="check"
                        v-if="selectedRole && selectedRole.name == role.name"
                      />
                    </v-col> </v-row
                ></v-list-item-content> </v-list-item></v-list-item-group
          ></v-list>
        </div>
      </div>
      <div class="permission-hearder" v-if="selectedRole">
        <div class="permission-user" v-if="selectedRole">
          <div class="title">Interfaces</div>
          <div class="fix-height-slide">
            <v-list class="list-category">
              <v-list-item-group>
                <v-list-item
                  button
                  v-for="permission in getListePermission"
                  :key="permission.key"
                  class="custom-list-group custom-list-group-vue"
                >
                  <v-list-item-content>
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          v-model="permission.checked"
                          @change="assignPermissionInterface(permission)"
                          color="#704ad1"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="8" @click="handleClickInterface(permission)">
                        <span class="capitalize"> {{ permission.name }}</span>
                      </v-col>
                      <v-col cols="2">
                        <font-awesome-icon
                          class="color-cdg"
                          icon="plus"
                          v-if="
                            permission &&
                              ((permission.filters &&
                                permission.filters.length != 0) ||
                                (permission.fonctionalite &&
                                  permission.fonctionalite.length != 0) ||
                                (permission.colonnes &&
                                  permission.colonnes.length != 0) ||
                                (permission.interfaces &&
                                  permission.interfaces.length != 0))
                          "
                        />
                      </v-col> </v-row
                  ></v-list-item-content> </v-list-item></v-list-item-group
            ></v-list>
          </div>
        </div>
      </div>
      <div
        class="permission-hearder-autorisations"
        v-if="
          selectedPage &&
            selectedPage &&
            (selectedPage.filters.length != 0 ||
              selectedPage.colonnes.length != 0 ||
              selectedPage.fonctionalite.length != 0)
        "
      >
        <div class="permission-user" v-if="selectedPage">
          <div class="title">
            Autorisations
            <div v-if="loading" class="chargement-message-text ml-2">
              Chargement en cours
            </div>
          </div>
          <div class="list-interface fix-height-slide">
            <div class="autorisation-permission custom-expansion-role">
              <v-expansion-panels>
                <!-- Filters  -->
                <v-expansion-panel
                  v-if="
                    selectedPage &&
                      selectedPage.filters &&
                      selectedPage.filters.length
                  "
                >
                  <v-expansion-panel-header :disable-icon-rotate="true">
                    <div class="container d-flex">
                      <p class="text-color">Filters</p>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-if="selectedPage.filters.length > 0"
                  >
                    <v-row class="row-style">
                      <v-col cols="1">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :value="computedChecked(selectedPage.filters)"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          @change="
                            checkAllPermissions($event, selectedPage.filters)
                          "
                          :checked="computedChecked(selectedPage.filters)"
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="11">Tous</v-col>
                    </v-row>

                    <v-row
                      class="row-style"
                      v-for="filter in selectedPage.filters"
                      :key="filter.key"
                    >
                      <v-col cols="1">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :id="'checkbox-group-1Tous' + filter.key"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          v-model="filter.checked"
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="11">
                        {{ filter.name }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          text
                          @click.prevent.stop="
                            assignPermission(selectedPage.filters, 'filter')
                          "
                          title="Enregistrer"
                          :loading="loadingFilter"
                          :disabled="loadingFilter"
                          class="btn-action-permession"
                        >
                          Enregistrer
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content></v-expansion-panel
                >
              </v-expansion-panels>
              <!-- End Filter  -->
              <!-- Colonnes  -->

              <v-expansion-panels>
                <v-expansion-panel
                  v-if="
                    selectedPage &&
                      selectedPage.colonnes &&
                      selectedPage.colonnes.length
                  "
                >
                  <v-expansion-panel-header :disable-icon-rotate="true">
                    <div class="container d-flex">
                      <p class="text-color">Colonnes</p>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-if="selectedPage.colonnes.length > 0"
                  >
                    <v-row class="row-style">
                      <v-col cols="1">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          :checked="computedChecked(selectedPage.colonnes)"
                          @change="
                            checkAllPermissions($event, selectedPage.colonnes)
                          "
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="11">Tous</v-col>
                    </v-row>

                    <v-row
                      class="row-style"
                      v-for="colonne in selectedPage.colonnes"
                      :key="colonne.key"
                    >
                      <v-col cols="1">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :id="'checkbox-group-1Tous' + colonne.key"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          v-model="colonne.checked"
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="11">
                        {{ colonne.name }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          text
                          @click.prevent.stop="
                            assignPermission(selectedPage.colonnes, 'colonne')
                          "
                          title="Enregistrer"
                          :loading="loadingColonne"
                          :disabled="loadingColonne"
                          class="btn-action-permession"
                        >
                          Enregistrer
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content></v-expansion-panel
                >
                <!-- End colonnes  -->
              </v-expansion-panels>
              <!-- Fonctionnalite  -->

              <v-expansion-panels>
                <v-expansion-panel
                  v-if="
                    selectedPage &&
                      selectedPage.fonctionalite &&
                      selectedPage.fonctionalite.length
                  "
                >
                  <v-expansion-panel-header :disable-icon-rotate="true">
                    <div class="container d-flex">
                      <p class="text-color">Fonctionnalités</p>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-if="selectedPage.fonctionalite.length > 0"
                  >
                    <v-row class="row-style">
                      <v-col cols="1">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          :checked="computedChecked(selectedPage.fonctionalite)"
                          @change="
                            checkAllPermissions(
                              $event,
                              selectedPage.fonctionalite
                            )
                          "
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="11">Tous</v-col>
                    </v-row>

                    <v-row
                      class="row-style"
                      v-for="fonction in selectedPage.fonctionalite"
                      :key="fonction.key"
                    >
                      <v-col cols="1">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :id="'checkbox-group' + fonction.key"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          v-model="fonction.checked"
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="11">
                        {{ fonction.name }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          text
                          @click.prevent.stop="
                            assignPermission(
                              selectedPage.fonctionalite,
                              'fonction'
                            )
                          "
                          title="Enregistrer"
                          :loading="loadingFonction"
                          :disabled="loadingFonction"
                          class="btn-action-permession"
                        >
                          Enregistrer
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content></v-expansion-panel
                >
              </v-expansion-panels>
              <!-- End Fonctionnalite  -->
            </div>
          </div>
        </div>
      </div>

      <div
        class="permission-hearder"
        v-if="selectedPage && selectedPage.interfaces.length != 0"
      >
        <div class="permission-user" v-if="selectedPage">
          <div class="title">Sous Interfaces</div>
          <div class="fix-height-slide">
            <v-list class="list-category">
              <v-list-item-group>
                <v-list-item
                  v-for="inter in selectedPage.interfaces"
                  :key="inter.key"
                  class="custom-list-group custom-list-group-vue"
                >
                  <v-list-item-content>
                    <v-row class="align-center"></v-row
                  ></v-list-item-content>
                  <v-col cols="2">
                    <v-checkbox
                      label=""
                      class="checked-global"
                      v-model="inter.checked"
                      color="#704ad1"
                      :value="true"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      v-if="checkPermission('GPRHTAPPS')"
                      @change="assignPermissionInterface(inter)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="8" @click="handleClickSousInterface(inter)">
                    <span class="capitalize"> {{ inter.name }}</span>
                  </v-col>
                  <v-col cols="2">
                    <font-awesome-icon
                      class="color-cdg"
                      icon="plus"
                      v-if="
                        inter &&
                          ((inter.filters && inter.filters.length != 0) ||
                            (inter.colonnes && inter.colonnes.length != 0) ||
                            (inter.fonctionalite &&
                              inter.fonctionalite.length != 0))
                      "
                    />
                  </v-col> </v-list-item></v-list-item-group
            ></v-list>
          </div>
        </div>
      </div>
      <div
        class="permission-hearder"
        v-if="
          selectedSousInterface &&
            (selectedSousInterface.filters.length != 0 ||
              selectedSousInterface.colonnes.length != 0 ||
              selectedSousInterface.fonctionalite.length != 0)
        "
      >
        <div class="permission-user" v-if="selectedPage">
          <div class="title">
            Autorisations
            <div v-if="loading" class="chargement-message-calender">
              Chargement en cours...
            </div>
          </div>
          <div class="list-interface fix-height-slide">
            <div class="autorisation-permission custom-expansion-role">
              <v-expansion-panels>
                <!-- Filters  -->
                <v-expansion-panel
                  v-if="
                    selectedSousInterface &&
                      selectedSousInterface.filters &&
                      selectedSousInterface.filters.length
                  "
                >
                  <v-expansion-panel-header :disable-icon-rotate="true">
                    <div class="container d-flex">
                      <p class="text-color">Filters</p>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-if="selectedSousInterface.filters.length > 0"
                  >
                    <v-row class="row-style">
                      <v-col cols="2">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          @change="
                            checkAllPermissions(
                              $event,
                              selectedSousInterface.filters
                            )
                          "
                          :checked="
                            computedChecked(selectedSousInterface.filters)
                          "
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="10">Tous</v-col>
                    </v-row>

                    <v-row
                      class="row-style"
                      v-for="filter in selectedSousInterface.filters"
                      :key="filter.key"
                    >
                      <v-col cols="2">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :id="'checkbox-group-1Tous' + filter.key"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          v-model="filter.checked"
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="10">
                        {{ filter.name }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          text
                          @click.prevent.stop="
                            assignPermission(
                              selectedSousInterface.filters,
                              'filter'
                            )
                          "
                          title="Enregistrer"
                          :loading="loadingFilter"
                          :disabled="loadingFilter"
                          class="btn-action-permession"
                        >
                          Enregistrer
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content></v-expansion-panel
                >
              </v-expansion-panels>
              <!-- End Filter  -->
              <!-- Colonnes  -->

              <v-expansion-panels>
                <v-expansion-panel
                  v-if="
                    selectedSousInterface &&
                      selectedSousInterface.colonnes &&
                      selectedSousInterface.colonnes.length
                  "
                >
                  <v-expansion-panel-header :disable-icon-rotate="true">
                    <div class="container d-flex">
                      <p class="text-color">Colonnes</p>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-if="selectedSousInterface.colonnes.length > 0"
                  >
                    <v-row class="row-style">
                      <v-col cols="2">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          :checked="
                            computedChecked(selectedSousInterface.colonnes)
                          "
                          @change="
                            checkAllPermissions(
                              $event,
                              selectedSousInterface.colonnes
                            )
                          "
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="10">Tous</v-col>
                    </v-row>

                    <v-row
                      class="row-style"
                      v-for="colonne in selectedSousInterface.colonnes"
                      :key="colonne.key"
                    >
                      <v-col cols="2">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :id="'checkbox-group-1Tous' + colonne.key"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          v-model="colonne.checked"
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="10">
                        {{ colonne.name }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          text
                          @click.prevent.stop="
                            assignPermission(
                              selectedSousInterface.colonnes,
                              'colonne'
                            )
                          "
                          title="Enregistrer"
                          :loading="loadingColonne"
                          :disabled="loadingColonne"
                          class="btn-action-permession"
                        >
                          Enregistrer
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content></v-expansion-panel
                >
                <!-- End colonnes  -->
              </v-expansion-panels>
              <!-- Fonctionnalite  -->

              <v-expansion-panels>
                <v-expansion-panel
                  v-if="
                    selectedSousInterface &&
                      selectedSousInterface.fonctionalite &&
                      selectedSousInterface.fonctionalite.length
                  "
                >
                  <v-expansion-panel-header :disable-icon-rotate="true">
                    <div class="container d-flex">
                      <p class="text-color">Fonctionnalités</p>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-if="selectedSousInterface.fonctionalite.length > 0"
                  >
                    <v-row class="row-style">
                      <v-col cols="2">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          :checked="
                            computedChecked(selectedSousInterface.fonctionalite)
                          "
                          @change="
                            checkAllPermissions(
                              $event,
                              selectedSousInterface.fonctionalite
                            )
                          "
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="10">Tous</v-col>
                    </v-row>

                    <v-row
                      class="row-style"
                      v-for="fonction in selectedSousInterface.fonctionalite"
                      :key="fonction.key"
                    >
                      <v-col cols="2">
                        <v-checkbox
                          label=""
                          class="checked-global"
                          color="#704ad1"
                          :id="'checkbox-group' + fonction.key"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          v-model="fonction.checked"
                          v-if="checkPermission('GPRHTAPPS')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="10">
                        {{ fonction.name }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          text
                          @click.prevent.stop="
                            assignPermission(
                              selectedSousInterface.fonctionalite,
                              'fonction'
                            )
                          "
                          title="Enregistrer"
                          :loading="loadingFonction"
                          :disabled="loadingFonction"
                          class="btn-action-permession"
                        >
                          Enregistrer
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content></v-expansion-panel
                >
              </v-expansion-panels>
              <!-- End Fonctionnalite  -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add Profil modal  -->
    <v-dialog
      v-model="addProfileModal"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Ajouter un profil </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addProfileModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2">
          <v-form class="mt-2" ref="form" lazy-validation>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-text-field
                  label="Nom *"
                  :persistent-placeholder="true"
                  v-model="roleToAdd.role"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  class="msg-error"
                  validate-on-blur
                  :rules="[v => !!v || 'Nom est obligatoire']"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col> </v-row></v-form
        ></v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="handleAddRole"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addProfileModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissionModel from '../../../models/permisionModel';
export default {
  data() {
    return {
      addProfileModal: false,
      loadingFilter: false,
      loadingColonne: false,
      loadingFonction: false,
      selectedRole: null,
      selectedPage: null,
      selectedSousInterface: null,
      interfaceAutorisation: [],
      sousInterfaceAutorisation: [],
      getListePermission: [],
      roleToAdd: {
        role: null
      },
      error: null,
      loading: false
    };
  },
  methods: {
    ...mapActions([
      'getAllRoles',
      'assignAutorisationToProfil',
      'removeAutorisationToProfil',
      'updateBlocked',
      'addNewRole'
    ]),
    hideModal(ref) {
      this[ref] = false;
      this.resetModal();
    },
    resetModal() {
      this.error = null;
      this.roleToAdd = { role: null };
    },
    async handleAddRole() {
      let role = {
        name: this.roleToAdd.role
      };
      this.loading = true;
      const response = await this.addNewRole(role);
      if (response == true) {
        this.loading = false;
        this.hideModal('addProfileModal');
      } else {
        this.loading = false;
        this.error = 'Une erreur est servenue';
      }
    },
    blockedUpdate(role, id) {
      let payload = {
        role: role,
        profile: id,
        blocked: 1
      };
      this.updateBlocked(payload);
    },
    deblockedUpdate(role, id) {
      let payload = {
        role: role,
        profile: id,
        blocked: 0
      };
      this.updateBlocked(payload);
    },
    async assignPermissionInterface(permission) {
      if (permission.checked == true) {
        let bodyFormData = new FormData();
        bodyFormData.append('role', this.selectedRole.id);
        bodyFormData.append('assigned[' + 0 + '][key]', permission.key);
        bodyFormData.append('assigned[' + 0 + '][name]', permission.name);
        let payload = {
          assigned: [permission],
          bodyFormData: bodyFormData,
          role: this.selectedRole.id
        };
        const response = await this.assignAutorisationToProfil(payload);
        if (response.success) {
        }
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append('role', this.selectedRole.id);
        bodyFormData.append('deleted[' + 0 + '][key]', permission.key);
        bodyFormData.append('deleted[' + 0 + '][name]', permission.name);
        let payload = {
          deleted: [permission],
          bodyFormData: bodyFormData,
          role: this.selectedRole.id
        };
        const response = await this.removeAutorisationToProfil(payload);
        if (response.success) {
        }
      }
    },
    checkAllPermissions(e, permissions) {
      permissions.map(element => {
        element.checked = e;
      });
    },
    async assignPermission(permissions, type) {
      let assigned = [];
      let deleted = [];
      assigned = permissions.filter(item => item.checked == true);
      deleted = permissions.filter(item => item.checked == false);
      if (assigned.length != 0) {
        this.loadingFilter = type == 'filter' ? true : false;
        this.loadingColonne = type == 'colonne' ? true : false;
        this.loadingFonction = type == 'fonction' ? true : false;
        let bodyFormData = new FormData();
        bodyFormData.append('role', this.selectedRole.id);
        assigned.forEach((element, index) => {
          bodyFormData.append('assigned[' + index + '][key]', element.key);
          bodyFormData.append('assigned[' + index + '][name]', element.name);
        });
        let payload = {
          assigned: assigned,
          bodyFormData: bodyFormData,
          role: this.selectedRole.id
        };
        const response = await this.assignAutorisationToProfil(payload);
        if (response.success) {
          this.loadingFilter = false;
          this.loadingFonction = false;
          this.loadingColonne = false;
        }
      }
      if (deleted.length != 0) {
        this.loadingFilter = type == 'filter' ? true : false;
        this.loadingColonne = type == 'colonne' ? true : false;
        this.loadingFonction = type == 'fonction' ? true : false;
        let bodyFormData = new FormData();
        bodyFormData.append('role', this.selectedRole.id);
        deleted.forEach((element, index) => {
          bodyFormData.append('deleted[' + index + '][key]', element.key);
          bodyFormData.append('deleted[' + index + '][name]', element.name);
        });
        let payload = {
          deleted: deleted,
          bodyFormData: bodyFormData,
          role: this.selectedRole.id
        };
        const response = await this.removeAutorisationToProfil(payload);
        if (response.success) {
          this.loadingFilter = false;
          this.loadingFonction = false;
          this.loadingColonne = false;
        }
      }
    },
    handleClickRole(role) {
      this.getListePermission.map(item => {
        item.checked = false;
      });
      this.selectedRole = role;
      this.selectedRole.permissions.map(permission => {
        this.getListePermission.map(item => {
          if (item.key == permission) {
            item.checked = true;
          }
        });
      });
      this.selectedPage = null;
      this.selectedSousInterface = null;
      this.interfaceAutorisation = [];
      this.sousInterfaceAutorisation = [];
    },
    handleClickInterface(permission) {
      this.selectedPage = permission;
      if (this.selectedPage.colonnes.length != 0) {
        this.selectedPage.colonnes.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedPage.filters.length != 0) {
        this.selectedPage.filters.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedPage.interfaces.length != 0) {
        this.selectedPage.interfaces.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedPage.fonctionalite.length != 0) {
        this.selectedPage.fonctionalite.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedPage.colonnes.length != 0) {
        this.selectedRole.permissions.map(per => {
          this.selectedPage.colonnes.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedPage.filters.length != 0) {
        this.selectedRole.permissions.map(per => {
          this.selectedPage.filters.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedPage.fonctionalite.length != 0) {
        this.selectedRole.permissions.map(per => {
          this.selectedPage.fonctionalite.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedPage.interfaces.length != 0) {
        this.selectedRole.permissions.map(per => {
          this.selectedPage.interfaces.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      this.selectedSousInterface = null;
    },
    handleClickSousInterface(inter) {
      this.selectedSousInterface = inter;
      if (this.selectedSousInterface.colonnes.length != 0) {
        this.selectedSousInterface.colonnes.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedSousInterface.filters.length != 0) {
        this.selectedSousInterface.filters.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedSousInterface.fonctionalite.length != 0) {
        this.selectedSousInterface.fonctionalite.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedSousInterface.filters.length != 0) {
        this.selectedRole.permissions.map(per => {
          this.selectedSousInterface.filters.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedSousInterface.fonctionalite.length != 0) {
        this.selectedRole.permissions.map(per => {
          this.selectedSousInterface.fonctionalite.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedSousInterface.colonnes.length != 0) {
        this.selectedRole.permissions.map(per => {
          this.selectedSousInterface.colonnes.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
    }
  },
  computed: {
    ...mapGetters(['roles', 'loadingAdd', 'checkPermission']),
    computedChecked() {
      return function(filters) {
        let tableCheck = filters.filter(item => item.checked == true);
        if (tableCheck.length == filters.length) {
          return true;
        }
        return false;
      };
    }
  },
  components: {},
  mounted() {
    this.getListePermission = permissionModel.create().list;
    this.getAllRoles();
  }
};
</script>

<style lang="scss" scoped>
.checked-global {
  margin-top: 0px;
}
.visibility-interface {
  width: 100%;
  height: 100%;
  .profile-content {
    display: flex;
    background-color: #f5f5f5c7;
    padding: 10px;
  }
  .card-block-filter-setting {
    padding: 5px 5px 5px 5px;
  }
  .box-content {
    padding-top: 0px !important;
    margin-bottom: 10px;
  }
  .title {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #2a2a2a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
  }

  .permission-hearder {
    height: 100%;
    padding: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 0px;
    width: 25%;
    max-width: 25%;
    margin-right: 10px;
  }

  .permission-hearder-autorisations {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 0px;
    width: 50%;
    max-width: 50%;
    margin-right: 10px;
  }
  .body-permission {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 0px;
    width: 27%;
  }
  .permission-body {
    width: 75%;
    display: flex;

    .permission-select-component {
      margin-bottom: 10px;
    }
    .permission-user,
    .permission-interface {
      width: 37%;
      padding: 5px;
    }
    .permission-autorisation {
      width: 25%;
      padding: 5px;
    }
  }
  .position-btn {
    position: relative;
    text-align-last: center;
    padding-top: 6px;
    .config-coef-btn {
      border-radius: 5px;
    }
  }
}
</style>
<style lang="scss">
.visibility-interface {
  .v-list-item__content {
    padding-left: 10px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
  #info-wrap {
    overflow: hidden;
  }

  .description-column {
    width: 72%;
    float: left;
    padding-left: 4px;
    font-size: 11px;
  }
  .info-column {
    width: 11%;
    float: left;
  }
  .interface-permission {
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 0px;
    font-size: medium;
  }
  .autorisation-permission {
    padding: 3px;
    padding-left: 5px;
    margin-bottom: 9px;
    font-size: medium;
  }

  .permission-hearder::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .permission-hearder::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .permission-hearder::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .permission-hearder::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }

  .permission-hearder::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .permission-hearder::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .permission-hearder::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .permission-hearder::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }

  .permission-hearder-autorisations {
    display: block;
    overflow-y: scroll !important;
    height: 10px;
    // height: calc(100vh - 50px);
  }
  .permission-hearder-autorisations::-webkit-scrollbar {
    width: 8px;
    // height: 100px;
  }

  /* Track */
  .permission-hearder-autorisations::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .permission-hearder-autorisations::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .permission-hearder-autorisations::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }

  .icon-color {
    color: #4d4bac;
  }
  .selected-role {
    cursor: pointer;
  }
  .check-permission {
    accent-color: #4d4bac;
    height: 20px;
    width: 20px;
  }

  .fix-height-slide {
    display: block;
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: calc(100vh - 277px);
    padding-right: 9px;
  }

  .fix-height-slide::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .fix-height-slide::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .fix-height-slide::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .fix-height-slide::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
  .btn-action-permession {
    color: rgb(112, 74, 209);
    caret-color: rgb(112, 74, 209);
    border: 1px solid;
  }
}
.custom-expansion-role {
  .v-expansion-panels {
    padding-top: 0px !important;
  }
  .text-color {
    margin-bottom: 0px !important;
    font-size: 13px;
    font-weight: 600;
  }
  .v-expansion-panel-header__icon {
    padding: 0px 14px !important;
  }
  .v-expansion-panel-header {
    height: 40px !important;
    min-height: 30px !important;
    padding: 5px !important;
  }
  .v-expansion-panel-content {
    font-size: 13px;
    .col-11,
    .col-10 {
      align-self: center;
    }
  }
}
</style>
