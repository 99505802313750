export default class permissionModel {
  constructor() {
    this.list = [
      //INSERTION BDD
      {
        name: 'Insertion BDD',
        key: 'IBD',
        checked: false,
        filters: [],
        colonnes: [],
        fonctionalite: [],
        interfaces: []
      },
      //Statistics General
      {
        name: 'Statistiques générales',
        key: 'SGCDG',
        checked: false,
        filters: [],
        colonnes: [],
        fonctionalite: [],
        interfaces: []
      },
      //ANALYSE & VALIDATION
      {
        name: 'Analyse et validation',
        key: 'A&VTH',
        checked: false,
        filters: [
          {
            name: 'Filter Date début , Date fin',
            key: 'A&VTHFDDDF',
            checked: false
          },
          {
            name: 'Filter Groupe par',
            key: 'A&VTHFGP',
            checked: false
          },
          {
            name: 'Filter par fichier xls',
            key: 'A&VTHFPFXLS',
            checked: false
          },

          {
            name: 'Filter Dépot',
            key: 'A&VTHFD',
            checked: false
          },
          {
            name: 'Filter Organisme',
            key: 'A&VTHFO',
            checked: false
          },
          {
            name: 'Filter Verrouillage',
            key: 'A&VTHFVG',
            checked: false
          },
          {
            name: 'Filter Statut',
            key: 'A&VTHFS',
            checked: false
          },

          {
            name: 'Filter Vue',
            key: 'A&VTHFV',
            checked: false
          },
          {
            name: 'Recherche',
            key: 'A&VTHRE',
            checked: false
          }
        ],
        fonctionalite: [
          {
            name: 'Télecharger xls',
            key: 'A&VTHTX',
            checked: false
          },
          {
            name: 'Vérrouiller & Déverrouiller dossier',
            key: 'A&VTHVADD',
            checked: false
          },
          {
            name: 'Modification globale de statut',
            key: 'A&VTHMQS',
            checked: false
          },
          {
            name: 'Modification des données',
            key: 'A&VTHMD',
            checked: false
          }
        ],
        colonnes: [],
        interfaces: []
      },
      //OBLIGEE
      {
        name: 'Obligés',
        key: 'FOTH',
        checked: false,
        filters: [],
        fonctionalite: [],
        colonnes: [],
        interfaces: [
          {
            name: 'Gestion des appels à paiement',
            key: 'GAPTH',
            checked: false,
            filters: [
              {
                name: 'Filter Date début',
                key: 'GAPTHFDD',
                checked: false
              },
              {
                name: 'Filter Date fin',
                key: 'GAPTHFDF',
                checked: false
              },
              {
                name: 'Filter Organisme',
                key: 'GAPTHFO',
                checked: false
              },
              {
                name: 'Filter Filiale installateur',
                key: 'GAPTHFFI',
                checked: false
              },
              {
                name: 'Filter Lot',
                key: 'GAPTHFL',
                checked: false
              },
              {
                name: 'Recherche',
                key: 'GAPTHRE',
                checked: false
              }
            ],
            fonctionalite: [
              {
                name: 'Afficher boutton géneration rapport',
                key: 'GAPTHABGR',
                checked: false
              },
              {
                name: 'Génération des factures Proforma Pour Master',
                key: 'GAPTHGFPPM',
                checked: false
              },

              {
                name: 'Afficher toutes les factures',
                key: 'VTFO',
                checked: false
              },

              {
                name: 'Refresh lot',
                key: 'GAPTHRL',
                checked: false
              },
              {
                name: 'Insertion xls',
                key: 'GAPTHIX',
                checked: false
              },
              {
                name: 'Validation globale des factures',
                key: 'GAPTHVGF',
                checked: false
              },
              {
                name: 'Télécharger Dossier xls',
                key: 'GAPTDX',
                checked: false
              },
              {
                name: 'Télécharger tous les factures xls',
                key: 'GAPTHXLS',
                checked: false
              },
              {
                name: 'Télecharger facture PDF (ZIP)',
                key: 'GAPTHTFP',
                checked: false
              },
              {
                name: 'Envoyer Email',
                key: 'GAPTHEE',
                checked: false
              },
              {
                name: 'Consulter dossier sans organisme',
                key: 'GAPTHDSO',
                checked: false
              },
              {
                name: 'Modifer coef prime installateur  par selection',
                key: 'GAPTHMCPIPS',
                checked: false
              },
              {
                name: 'Modifier Kwh cumac',
                key: 'GAPTHMKC',
                checked: false
              },
              {
                name: 'Modifier Coef prime installateur',
                key: 'GAPTHMCPIPU',
                checked: false
              },
              {
                name: 'Modifier Montant Prime CEE',
                key: 'GAPTHMMPC',
                checked: false
              },
              {
                name: 'Afficher les colonnes prime',
                key: 'GAPTHACP',
                checked: false
              },
              {
                name: 'Validation facture prime',
                key: 'GAPTHVFP',
                checked: false
              },
              {
                name: 'Validation facture consolidee (Energie Invest)',
                key: 'GAPTHVFCEI',
                checked: false
              },
              {
                name: 'télecharger facture prime',
                key: 'GAPTHTFPR',
                checked: false
              },
              {
                name: 'télecharger facture consolidee(Energie Invest)',
                key: 'GAPTHTFCEI',
                checked: false
              },
              {
                name: 'Afficher historique facture prime',
                key: 'GAPTHAHPR',
                checked: false
              },
              {
                name: 'Afficher historique facture consolidée (Energie Invest)',
                key: 'GAPTHAHCEI',
                checked: false
              },
              {
                name: 'Afficher la colonne avoir prime',
                key: 'GAPTHACAP',
                checked: false
              },
              {
                name: 'genérer les factures avoir prime',
                key: 'GAPTHFAP',
                checked: false
              },
              {
                name: 'genérer les factures avoir consolidée (Energie Invest)',
                key: 'GAPTHFACEI',
                checked: false
              },
              {
                name:
                  'Télecharger les factures avoir consolidée (Energie Invest) en ZIP',
                key: 'GAPTHTFACEIZ',
                checked: false
              },
              {
                name: 'Télecharger les factures avoir prime en ZIP',
                key: 'GAPTHTFAPZ',
                checked: false
              },
              {
                name: 'Télecharger pdf facture avoir prime',
                key: 'GAPTHTPFAP',
                checked: false
              },
              {
                name:
                  'Télecharger pdf facture avoir consolidée (Energie Invest)',
                key: 'GAPTHTPFACEI',
                checked: false
              },
              {
                name: 'Afficher historique facture avoir prime',
                key: 'GAPTHAHAPR',
                checked: false
              },
              {
                name: 'Visualiser facture avoir prime',
                key: 'GAPTHVFAP',
                checked: false
              },
              {
                name: 'Visualiser facture avoir consolidée (Energie Invest)',
                key: 'GAPTHVFACEI',
                checked: false
              },
              {
                name: 'Modifier le statut prime',
                key: 'GAPTHMSP',
                checked: false
              },
              {
                name: 'Modifier globalement le statut prime',
                key: 'GAPTHMSPG',
                checked: false
              },
              {
                name: 'Validation facture installateur',
                key: 'GAPTHVFI',
                checked: false
              },
              {
                name: 'télecharger facture installateur',
                key: 'GAPTHTFIR',
                checked: false
              },
              {
                name: 'Afficher historique facture  prime',
                key: 'GAPTHAFIR',
                checked: false
              },
              {
                name: 'genérer les factures avoir installateur',
                key: 'GAPTHGFAI',
                checked: false
              },
              {
                name: 'Télecharger les factures avoir installateur en ZIP',
                key: 'GAPTHTFAIZ',
                checked: false
              },
              {
                name: 'Télecharger pdf facture avoir installateur',
                key: 'GAPTHTPFAI',
                checked: false
              },
              {
                name: 'Afficher historique facture avoir installateur',
                key: 'GAPTHAHFAI',
                checked: false
              },
              {
                name: 'Visualiser facture avoir installateur',
                key: 'GAPTHVFAI',
                checked: false
              },
              {
                name: 'Modifier le statut installateur',
                key: 'GAPTHMSPI',
                checked: false
              },
              {
                name: 'Modifier globalement le statut installateur',
                key: 'GAPTHMSPIG',
                checked: false
              },
              {
                name: 'Télecharger pdf facture avoir commission gestion',
                key: 'GAPTHTPFACG',
                checked: false
              },
              {
                name: 'Visualiser facture avoir commission gestion',
                key: 'GAPTHVFACG',
                checked: false
              },
              {
                name: 'Validation facture commission gestion',
                key: 'GAPTHVFCG',
                checked: false
              },
              {
                name: 'Télecharger facture commission gestion',
                key: 'GAPTHTFCG',
                checked: false
              },
              {
                name: 'Afficher historique facture commission gestion',
                key: 'GAPTHAHFCG',
                checked: false
              },
              {
                name: 'Modifier le statut commission gestion',
                key: 'GAPTHMSCG',
                checked: false
              },
              {
                name: 'Genérer les factures avoir commission gestion',
                key: 'GAPTHFACG',
                checked: false
              },
              {
                name:
                  'Télecharger les factures avoir commmission gestion en ZIP',
                key: 'GAPTHTFACGZ',
                checked: false
              },
              {
                name: 'Afficher historique facture avoir commmission gestion ',
                key: 'GAPTHAHFACG',
                checked: false
              },
              {
                name: 'Validation facture Commission',
                key: 'GAPTHVFC',
                checked: false
              },
              {
                name: 'télecharger facture Commission',
                key: 'GAPTHTFCR',
                checked: false
              },
              {
                name: 'Afficher historique facture Commission',
                key: 'GAPTHAHFCR',
                checked: false
              },
              {
                name: 'genérer les factures avoir Commission',
                key: 'GAPTHGFAC',
                checked: false
              },
              {
                name: 'Télecharger les factures avoir Commission en ZIP',
                key: 'GAPTHTFACZ',
                checked: false
              },
              {
                name: 'Télecharger pdf facture avoir Commission',
                key: 'GAPTHTPFAC',
                checked: false
              },
              {
                name: 'Visualiser facture avoir Commission',
                key: 'GAPTHVFAC',
                checked: false
              },
              {
                name: 'Afficher historique facture avoir Commission',
                key: 'GAPTHAHFAC',
                checked: false
              },
              {
                name: 'Modifier Coef Commission',
                key: 'GAPTHMCFC',
                checked: false
              },
              {
                name: 'Modifier le statut Commission',
                key: 'GAPTHMSPC',
                checked: false
              },
              {
                name: 'Modifier globalement le statut Commission',
                key: 'GAPTHMSPCG',
                checked: false
              },
              {
                name: 'Validation facture surprime',
                key: 'GAPTHVFS',
                checked: false
              },
              {
                name: 'télecharger facture surprime',
                key: 'GAPTHTFSR',
                checked: false
              },
              {
                name: 'Afficher historique facture surprime',
                key: 'GAPTHAHFSR',
                checked: false
              },
              {
                name: 'genérer les factures avoir surprime',
                key: 'GAPTHGFAS',
                checked: false
              },
              {
                name: 'Télecharger les factures avoir surprime en ZIP',
                key: 'GAPTHTFASZ',
                checked: false
              },
              {
                name: 'Télecharger pdf facture avoir surprime',
                key: 'GAPTHTPFAS',
                checked: false
              },
              {
                name: 'Afficher historique facture avoir surprime',
                key: 'GAPTHAHFASR',
                checked: false
              },
              {
                name: 'Visualiser facture',
                key: 'GAPTHVFO',
                checked: false
              },
              {
                name: 'Visualiser facture avoir surprime',
                key: 'GAPTHVFAS',
                checked: false
              },
              {
                name: 'Modifier Coef surprime',
                key: 'GAPTHMCS',
                checked: false
              },
              {
                name: 'Modifier le statut surprime',
                key: 'GAPTHMSPS',
                checked: false
              },
              {
                name: 'Modifier globalement le statut surprime',
                key: 'GAPTHMSPSG',
                checked: false
              },
              {
                name: 'Supprimer project',
                key: 'GAPTHDP',
                checked: false
              },
              {
                name: 'Modifier globalement le statut commission gestion',
                key: 'GAPTHMGSCG',
                checked: false
              },
              {
                name: 'Ajouter règlement',
                key: 'APTHAR',
                checked: false
              },
              {
                name: 'Modifier règlement',
                key: 'APTHUR',
                checked: false
              }
            ],
            colonnes: [
              {
                name: 'Afficher les colonnes installateur',
                key: 'GAPTHACI',
                checked: false
              },

              {
                name: 'Afficher la colonne avoir installateur',
                key: 'GAPTHACAI',
                checked: false
              },

              {
                name: 'Afficher les colonnes Commission',
                key: 'GAPTHACC',
                checked: false
              },

              {
                name: 'Afficher la colonne avoir commission',
                key: 'GAPTHACAC',
                checked: false
              },

              {
                name: 'Afficher les colonnes surprime',
                key: 'GAPTHACS',
                checked: false
              },

              {
                name: 'Afficher la colonne avoir surprime',
                key: 'GAPTHACAS',
                checked: false
              },

              {
                name: 'Afficher les colonnes commission gestion',
                key: 'GAPTHACCG',
                checked: false
              },
              {
                name: 'Afficher la colonne avoir commission gestion',
                key: 'GAPTHACACG',
                checked: false
              },
              {
                name:
                  'Afficher les colonnes Facture consolidée (Energie Invest)',
                key: 'GAPTHACFCEI',
                checked: false
              },
              {
                name: 'Afficher la colonne avoir consolidée Energie Invest',
                key: 'GAPTHACACEI',
                checked: false
              }
            ]
          },
          {
            name: 'Statistique',
            key: 'GOTHS',
            checked: false
          }
        ]
      },
      //LIBRE
      {
        name: 'Facture libre',
        key: 'FL',
        checked: false,
        filters: [
          { name: 'Filter Nom Produit', key: 'FLFNP', checked: false },
          { name: 'Filter N° Facture', key: 'FLFFNF', checked: false },
          { name: 'Filter Objet', key: 'FLFO', checked: false },
          { name: 'Filter Type', key: 'FLFT', checked: false },
          { name: 'Filter Etat', key: 'FLFE', checked: false },
          { name: 'Filter Mode de règlement', key: 'FLFMR', checked: false },
          { name: 'Filter Vendeur', key: 'FLFV', checked: false },
          { name: 'Filter Acheteur', key: 'FLFA', checked: false },
          { name: 'Filter Catégorie', key: 'FLFC', checked: false },
          { name: 'Filter Date début', key: 'FLFDD', checked: false },
          { name: 'Filter Date fin', key: 'FLFDF', checked: false }
        ],
        colonnes: [
          {
            name: 'Afficher colonne type document',
            key: 'FLACTD',
            checked: false
          },
          { name: 'Afficher colonne societe', key: 'FLACS', checked: false },
          { name: 'Afficher colonne numéro', key: 'FLACN', checked: false },
          {
            name: 'Afficher colonne date de création',
            key: 'FLACDC',
            checked: false
          },
          { name: 'Afficher colonne client', key: 'FLACC', checked: false },
          {
            name: 'Afficher colonne Reste à payer',
            key: 'FLACRAP',
            checked: false
          },
          { name: 'Afficher colonne état', key: 'FLACE', checked: false },
          {
            name: 'Afficher colonne date de paiement',
            key: 'FLADP',
            checked: false
          },
          {
            name: 'Afficher colonne reglement',
            key: 'FLARG',
            checked: false
          },
          {
            name: 'Afficher colonne catégorie',
            key: 'FLACCAT',
            checked: false
          },
          { name: 'Afficher colonne net ht', key: 'FLACNHT', checked: false },
          {
            name: 'Afficher colonne tva',
            key: 'FLACTVA',
            checked: false
          },
          {
            name: 'Afficher colonne total ttc',
            key: 'FLACTTTC',
            checked: false
          },
          {
            name: 'Afficher colonne statue client',
            key: 'FLACSC',
            checked: false
          },
          {
            name: 'Afficher colonne confirmation',
            key: 'FLACCONF',
            checked: false
          },
          {
            name: 'Afficher colonne Crée par',
            key: 'FLACCP',
            checked: false
          },
          {
            name: 'Afficher colonne reference proforma',
            key: 'FLACRP',
            checked: false
          },
          { name: 'Afficher colonne actions', key: 'FLAA', checked: false },
          { name: 'Afficher button recherche', key: 'FLABR', checked: false }
        ],
        fonctionalite: [
          {
            name: 'Afficher toutes les factures',
            key: 'VTFL',
            checked: false
          },
          {
            name: 'Envoyer Email',
            key: 'FLEES',
            checked: false
          },
          {
            name: 'Télécharger XLS',
            key: 'FLXLS',
            checked: false
          },
          {
            name: 'Télécharger ZIP',
            key: 'FLTZIP',
            checked: false
          },
          {
            name: 'Confirmer',
            key: 'FLC',
            checked: false
          },
          {
            name: "Vider l'ordre",
            key: 'FLVO',
            checked: false
          },
          // {
          //   name: 'Modification état facture',
          //   key: 'FLMES',
          //   checked: false
          // },
          {
            name: 'Modification état facture Client',
            key: 'FLMESC',
            checked: false
          },
          {
            name: 'Afficher facture',
            key: 'FLAF',
            checked: false
          },
          {
            name: 'Pièces jointes vue par défaut',
            key: 'FLPJVPD',
            checked: false
          },
          {
            name: 'Historique Facture vue par défaut',
            key: 'FLHFVPD',
            checked: false
          },
          {
            name: 'Historique Facture Avoir vue par défaut',
            key: 'FLHFAVPD',
            checked: false
          },
          {
            name: 'Pièces jointes vue client',
            key: 'FLPJVC',
            checked: false
          },
          {
            name: 'Historique Facture vue client',
            key: 'FLHFVC',
            checked: false
          },
          {
            name: 'Historique Facture Avoir vue client',
            key: 'FLHFAVC',
            checked: false
          },
          {
            name: 'Creer une nouvelle facture',
            key: 'FLCNF',
            checked: false
          },
          {
            name: 'Modifier facture',
            key: 'FLMF',
            checked: false
          },
          {
            name: 'Modifier facture financier',
            key: 'FLMFFC',
            checked: false
          },
          {
            name: 'Télécharger facture en pdf',
            key: 'FLTFP',
            checked: false
          },
          {
            name: 'Dupliquer facture',
            key: 'FLDF',
            checked: false
          },
          {
            name: 'Avoir sur facture',
            key: 'FLAVF',
            checked: false
          },
          {
            name: 'Ordre colonne',
            key: 'FLOC',
            checked: false
          },
          {
            name: 'Affiche les Boutons précedent et suivant',
            key: 'FLABPS',
            checked: false
          },
          { name: 'Reglement', key: 'FLRG', checked: false },
          { name: 'Modifer reglement', key: 'FLURG', checked: false },
          {
            name: 'Création avoir pour tout',
            key: 'FLCAPT',
            checked: false
          },
          { name: 'Date de facturation', key: 'FLDFAC', checked: false },
          {
            name: 'Date de facturation avoir',
            key: 'FLDCFA',
            checked: false
          },
          {
            name: 'Ajouter un client passage',
            key: 'FLACP',
            checked: false
          },
          {
            name: 'Genération des factures avoir',
            key: 'GGDFTHGFA',
            checked: false
          },
        ],
        interfaces: []
      },
      //SCI
      {
        name: 'Factures SCI',
        key: 'FSTH',
        checked: false,
        filters: [
          {
            name: 'Filter :Date début , Date fin',
            key: 'FSDDF',
            checked: false
          },
          {
            name: 'Filter Année / Mois',
            key: 'FSFAM',
            checked: false
          },
          {
            name: 'Filter Société',
            key: 'FSFS',
            checked: false
          },
          {
            name: 'Filter Client',
            key: 'FSFC',
            checked: false
          },
          {
            name: 'Filter Statut',
            key: 'FSFST',
            checked: false
          },
          {
            name: 'Filter Statut client',
            key: 'FSFSC',
            checked: false
          },
          {
            name: 'Recherche',
            key: 'FSR',
            checked: false
          }
        ],
        colonnes: [
          {
            name: 'Afficher colonne client',
            key: 'FSACC',
            checked: false
          },
          {
            name: 'Afficher colonne société',
            key: 'FSACS',
            checked: false
          },
          {
            name: 'Afficher colonne Montant HT',
            key: 'FSACMH',
            checked: false
          },
          {
            name: 'Afficher colonne TVA',
            key: 'FSACT',
            checked: false
          },

          {
            name: 'Afficher colonne Montant TTC',
            key: 'FSTTC',
            checked: false
          },
          {
            name: 'Afficher colonne Numéro de facture',
            key: 'FSACNF',
            checked: false
          },
          {
            name: 'Afficher colonne Numéro de facture Avoir',
            key: 'FSACNFA',
            checked: false
          },
          {
            name: 'Afficher colonne Reste à payer',
            key: 'FSACRAP',
            checked: false
          },
          {
            name: 'Afficher colonne statut de facture',
            key: 'FSACSFA',
            checked: false
          },
          {
            name: 'Afficher colonne statut client',
            key: 'FSACSC',
            checked: false
          },
          {
            name: 'Afficher colonne Date de facture',
            key: 'FSACD',
            checked: false
          },
          {
            name: 'Afficher colonne Mois de facture',
            key: 'FSACMF',
            checked: false
          },
          {
            name: 'Afficher colonne date de paiement',
            key: 'FSACSCDP',
            checked: false
          },
          {
            name: 'Afficher colonne reglement',
            key: 'FSACSCRG',
            checked: false
          },
          {
            name: 'Afficher colonne Historique',
            key: 'FSACHF',
            checked: false
          }
        ],
        fonctionalite: [
          {
            name: 'Afficher toutes les factures',
            key: 'VTFSCI',
            checked: false
          },

          {
            name: 'Ajouter Facture',
            key: 'FSAF',
            checked: false
          },

          {
            name: 'Afficher block total',
            key: 'FSACDABT',
            checked: false
          },
          {
            name: 'Validation multiple des factures',
            key: 'FSVMF',
            checked: false
          },
          {
            name: 'Genération des factures avoir',
            key: 'FSGFA',
            checked: false
          },

          {
            name: 'Validation de facture',
            key: 'FSVF',
            checked: false
          },
          {
            name: 'Afficher la facture',
            key: 'FSAFM',
            checked: false
          },
          // {
          //   name: 'Modifier le statut par facture',
          //   key: 'FSSF',
          //   checked: false
          // },
          // {
          //   name: 'Modifier le statut client par facture',
          //   key: 'FSMSCF',
          //   checked: false
          // },
          {
            name: 'Modifier Montant HT',
            key: 'FSMMHT',
            checked: false
          },
          {
            name: 'Générer des avoirs partiels',
            key: 'FSGAP',
            checked: false
          },
          // {
          //   name: 'Modification Multiple Statuts factures',
          //   key: 'FSMMSF',
          //   checked: false
          // },
          // {
          //   name: 'Modification Multiple Statut clients factures',
          //   key: 'FSMMSCF',
          //   checked: false
          // },
          { name: 'Télecharger XLS', key: 'FSTX', checked: false },
          { name: 'Télecharger ZIP', key: 'FSTZ', checked: false },
          {
            name: 'Télecharger facture  pdf',
            key: 'FSTFP',
            checked: false
          },
          {
            name: 'Télecharger facture avoir  pdf',
            key: 'FSTFAP',
            checked: false
          },
          {
            name:
              'Transfert des factures annulées vers interface des factures libres',
            key: 'FSTFATFL',
            checked: false
          },
          {
            name: 'Envoyer Email',
            key: 'FSTHEM',
            checked: false
          },
          {
            name: 'Ajouter reglèment',
            key: 'FSACSAR',
            checked: false
          },
          {
            name: 'Modifier reglèment',
            key: 'FSACSUR',
            checked: false
          },
          {
            name: "Date création de l'avoir",
            key: 'FSACSDCA',
            checked: false
          }
        ],
        interfaces: []
      },
      //Règlement Factures
      {
        name: 'Règlement Factures',
        key: 'RFTH',
        checked: false,
        filters: [
          {
            name: 'Filter :Date début , Date fin',
            key: 'RFTHDDF',
            checked: false
          },

          {
            name: 'Filter Chercher par',
            key: 'RFTHFCP',
            checked: false
          },
          {
            name: 'Filter Type Règlement',
            key: 'RFTHFTR',
            checked: false
          },
          {
            name: 'Filter Partenaire',
            key: 'RFTHFP',
            checked: false
          },
          {
            name: 'Filter Type Partenaire',
            key: 'RFTHFTP',
            checked: false
          },
          {
            name: 'Filter Client',
            key: 'RFTHFTC',
            checked: false
          },
          {
            name: 'Filter Société',
            key: 'RFTHFTS',
            checked: false
          },
          {
            name: 'Filter Comptabilisé',
            key: 'RFTHFC',
            checked: false
          },
          {
            name: 'Filter Etat Règlement',
            key: 'RFTHFER',
            checked: false
          },
          {
            name: 'Filter Etat de lettrage',
            key: 'RFTHFEL',
            checked: false
          }
        ],
        colonnes: [
          {
            name: 'Afficher colonne identifiant règlement',
            key: 'RFTHACIR',
            checked: false
          },
          {
            name: 'Afficher colonne Partenaire',
            key: 'RFTHACP',
            checked: false
          },
          {
            name: 'Afficher colonne Client',
            key: 'RFTHACCL',
            checked: false
          },
          {
            name: 'Afficher colonne Type Partenaire',
            key: 'RFTHACTP',
            checked: false
          },
          {
            name: 'Afficher colonne Type Règlement',
            key: 'RFTHACTR',
            checked: false
          },

          {
            name: 'Afficher colonne Montant TTC',
            key: 'RFTHACMT',
            checked: false
          },
          {
            name: 'Afficher colonne Balance',
            key: 'RFTHACB',
            checked: false
          },
          {
            name: 'Afficher colonne Date de paiement',
            key: 'RFTHACDP',
            checked: false
          },
          {
            name: 'Afficher colonne Réference',
            key: 'RFTHACR',
            checked: false
          },
          {
            name: 'Afficher colonne note',
            key: 'RFTHACN',
            checked: false
          },
          {
            name: 'Afficher colonne Comptabilisé',
            key: 'RFTHACC',
            checked: false
          }
        ],
        fonctionalite: [
          {
            name: 'Nouveau Règlement',
            key: 'RFTHNR',
            checked: false
          },
          {
            name: 'Modifier Règlement',
            key: 'RFTHMR',
            checked: false
          },
          {
            name: 'Supprimer Règlement',
            key: 'RFTHSR',
            checked: false
          },
          {
            name: 'Pièce jointes',
            key: 'RFTHPJ',
            checked: false
          }
        ],
        interfaces: []
      },
      //refresh facture
      // {
      //   name: 'Refresh Factures',
      //   key: 'REFFTH',
      //   checked: false,
      //   filters: [
      //     { name: 'Filter Date début', key: 'REFFTHFDD', checked: false },
      //     { name: 'Filter Date fin', key: 'REFFTHFDF', checked: false },
      //     { name: 'Filter Société', key: 'REFFTHFV', checked: false },
      //     { name: 'Filter Client', key: 'REFFTHFA', checked: false },
      //     { name: 'Filter Famille', key: 'REFFTHFF', checked: false },
      //     { name: 'Filter Catégorie', key: 'REFFTHFC', checked: false },
      //     { name: 'Filter Statut', key: 'REFFTHFS', checked: false },
      //     { name: 'Filter Type', key: 'REFFTHFT', checked: false },
      //     { name: 'Filter Comptabilisé', key: 'REFFTHFCO', checked: false },
      //     { name: 'Filter Lot', key: 'REFFTHFL', checked: false },
      //     { name: 'Recherche', key: 'REFFTHR', checked: false }
      //   ],
      //   colonnes: [
      //     {
      //       name: 'Afficher colonne Vendeur ',
      //       key: 'REFFTHACV',
      //       checked: false
      //     },
      //     { name: 'Afficher colonne Numéro', key: 'REFFTHACN', checked: false },
      //     {
      //       name: 'Afficher colonne Famille',
      //       key: 'REFFTHACF',
      //       checked: false
      //     },
      //     { name: 'Afficher colonne Type', key: 'REFFTHACT', checked: false },
      //     {
      //       name: 'Afficher colonne Date de création',
      //       key: 'REFFTHACDDC',
      //       checked: false
      //     },
      //     {
      //       name: 'Afficher colonne Acheteur',
      //       key: 'REFFTHACA',
      //       checked: false
      //     },
      //     { name: 'Afficher colonne Etat', key: 'REFFTHACE', checked: false },
      //     {
      //       name: 'Afficher colonne Date de paiement',
      //       key: 'REFFTHACDDP',
      //       checked: false
      //     },
      //     {
      //       name: 'Afficher colonne Catégorie',
      //       key: 'REFFTHAC',
      //       checked: false
      //     },
      //     {
      //       name: 'Afficher colonne NET HT',
      //       key: 'REFFTHACNETHT',
      //       checked: false
      //     },
      //     {
      //       name: 'Afficher colonne TVA',
      //       key: 'REFFTHACTVA',
      //       checked: false
      //     },
      //     {
      //       name: 'Afficher colonne Total TTC',
      //       key: 'REFFTHACTTC',
      //       checked: false
      //     },
      //     {
      //       name: 'Afficher colonne Comptabilisé',
      //       key: 'REFFTHACCOM',
      //       checked: false
      //     }
      //   ],
      //   fonctionalite: [
      //     {
      //       name: 'Actualise les factures',
      //       key: 'REFFTHAF',
      //       checked: false
      //     }
      //   ]
      // },
      //Factures commerciaux
      {
        name: 'Factures commerciaux',
        key: 'FC',
        checked: false,
        filters: [],
        fonctionalite: [],
        colonnes: [],
        interfaces: [
          {
            name: 'Appel à paiement',
            key: 'GAPC',
            checked: false,
            filters: [
              {
                name: 'Filtre Date début , Date fin',
                key: 'GAPCFDF',
                checked: false
              },
              {
                name: 'Filtre  installateur',
                key: 'GAPCFI',
                checked: false
              },
              {
                name: 'Filtre Lot',
                key: 'GAPCFL',
                checked: false
              },
              {
                name: 'Filtre Appel à paiement',
                key: 'GAPCFAAP',
                checked: false
              },
              {
                name: 'Filtre Régie',
                key: 'GAPCFRE',
                checked: false
              },
              {
                name: 'Filtre Commercial',
                key: 'GAPCFCO',
                checked: false
              },
              {
                name: 'Recherche',
                key: 'GAPTHREC',
                checked: false
              },
              {
                name: 'Filtre Reste à charge',
                key: 'GAPRACAAP',
                checked: false
              },
              {
                name: 'Filtre Statut',
                key: 'GAPCFSC',
                checked: false
              },
            ],
            fonctionalite: [
              {
                name: 'Modifier colonne sous régie',
                key: 'GAPTHMCSR',
                checked: false
              },
              {
                name: 'Modifier colonne commercial reste à charge',
                key: 'GAPTHMCCRAC',
                checked: false
              },
              {
                name: 'Modifier colonne agent commercial terrain',
                key: 'GAPTHMCSAP'
              },
              {
                name: 'Modifier colonne statut appel à paiement',
                key: 'GAPTHMCACT'
              },
              {
                name:
                  'Modification multiple des colonnes sous régie et restte à charge',
                key: 'GAPTHMMCSRCRAC',
                checked: false
              },
              {
                name: 'Appel à paiement',
                key: 'GAPTHAAP',
                checked: false
              },
              // {
              //   name: "Annuler l'appel à paiement",
              //   key: 'GAPTHAAAP',
              //   checked: false
              // },
              {
                name: 'Régeneration de facture',
                key: 'GAPTHARF',
                checked: false
              },

              {
                name: "Vider l'ordre des colonnes",
                key: 'GAPCVOC',
                checked: false
              }
            ],
            colonnes: [
              {
                name: 'Afficher colonne Numero de dossier',
                key: 'GAPTHACND',
                checked: false
              },
              {
                name: 'Afficher colonne installateur',
                key: 'GAPTHACIN',
                checked: false
              },
              {
                name: 'Afficher colonne nom',
                key: 'GAPTHACNOM',
                checked: false
              },
              {
                name: 'Afficher colonne prénom',
                key: 'GAPTHACPR',
                checked: false
              },
              {
                name: 'Afficher colonne régie',
                key: 'GAPTHACRG',
                checked: false
              },
              {
                name: 'Afficher colonne date dépôt de dossier',
                key: 'GAPTHACDD',
                checked: false
              },
              {
                name: 'Afficher colonne statut',
                key: 'GAPTHACST',
                checked: false
              },
              {
                name: 'Afficher colonne sous régie',
                key: 'GAPTHACSR',
                checked: false
              },
              {
                name: 'Afficher colonne commercial reste à charge',
                key: 'GAPTHACCRAC',
                checked: false
              },
              {
                name: 'Afficher colonne agent commercial terrain',
                key: 'GAPTHACACT',
                checked: false
              },
              {
                name: 'Afficher colonne Pièce jointe',
                key: 'GAPTHFJ',
                checked: false
              },
              {
                name: 'Afficher colonne Statut appel à paiement',
                key: 'GAPTHSAP',
                checked: false
              }
            ]
          },
          {
            name: 'Factures commerciaux',
            key: 'FCTH',
            checked: false,
            filters: [
              {
                name: 'Filter Date début , Date fin',
                key: 'FCTHFDF',
                checked: false
              },
              // {
              //   name: 'Filter Année / Mois',
              //   key: 'FCTHFAM',
              //   checked: false
              // },
              {
                name: 'Filter Société',
                key: 'FCTHS',
                checked: false
              },
              {
                name: 'Filter Client',
                key: 'FCTHC',
                checked: false
              },
              {
                name: 'Filter Statut',
                key: 'FCTHST',
                checked: false
              },
              {
                name: 'Filter Statut client',
                key: 'FCTHSTC',
                checked: false
              },
              {
                name: 'Recherche',
                key: 'FCTHR',
                checked: false
              }
            ],
            colonnes: [
              {
                name: 'Afficher colonne client',
                key: 'FCTHACC',
                checked: false
              },
              {
                name: 'Afficher colonne société',
                key: 'FCTHACS',
                checked: false
              },
              {
                name: 'Afficher colonne Montant HT',
                key: 'FCTHAMH',
                checked: false
              },
              {
                name: 'Afficher colonne TVA',
                key: 'FCTHAT',
                checked: false
              },
              {
                name: 'Afficher colonne Montant TTC',
                key: 'FCTHATTC',
                checked: false
              },
              {
                name: 'Afficher colonne Numéro de facture',
                key: 'FCTHANF',
                checked: false
              },
              {
                name: 'Afficher colonne Reste à payer',
                key: 'FCTHARAP',
                checked: false
              },
              {
                name: 'Afficher colonne statut de facture',
                key: 'FCTHASF',
                checked: false
              },
              {
                name: 'Afficher colonne statut client',
                key: 'FCTHASC',
                checked: false
              },
              {
                name: 'Afficher colonne Date de facture',
                key: 'FCTHADF',
                checked: false
              },
              {
                name: 'Afficher colonne date de paiement',
                key: 'FCTHACDP',
                checked: false
              },
              {
                name: 'Afficher colonne reglement',
                key: 'FCTHACR',
                checked: false
              },
              {
                name: 'Afficher colonne Historique',
                key: 'FCTHACH',
                checked: false
              },
              {
                name: 'Afficher colonne numéro de dossier',
                key: 'FCTHACND',
                checked: false
              },
              {
                name: 'Afficher colonne nom et prénom',
                key: 'project_nom_prenom',
                checked: false
              }
            ],
            fonctionalite: [
              {
                name: 'Afficher toutes les factures',
                key: 'VTFSCI',
                checked: false
              },

              {
                name: 'Ajouter Facture',
                key: 'FSAF',
                checked: false
              },

              {
                name: 'Afficher block total',
                key: 'FSACDABT',
                checked: false
              },
              {
                name: 'Validation multiple des factures',
                key: 'FCTHVMF',
                checked: false
              },
              {
                name: 'Genération des factures avoir',
                key: 'FCTHGFA',
                checked: false
              },

              {
                name: 'Validation de facture',
                key: 'FCTHVF',
                checked: false
              },
              {
                name: 'Afficher la facture',
                key: 'FCTHAFM',
                checked: false
              },
              // {
              //   name: 'Modifier le statut par facture',
              //   key: 'FSSF',
              //   checked: false
              // },
              // {
              //   name: 'Modifier le statut client par facture',
              //   key: 'FSMSCF',
              //   checked: false
              // },
              {
                name: 'Modifier Montant HT',
                key: 'FSMMHT',
                checked: false
              },
              // {
              //   name: 'Modification Multiple Statuts factures',
              //   key: 'FSMMSF',
              //   checked: false
              // },
              // {
              //   name: 'Modification Multiple Statut clients factures',
              //   key: 'FSMMSCF',
              //   checked: false
              // },
              { name: 'Télecharger XLS', key: 'FCTHTX', checked: false },
              { name: 'Télecharger ZIP', key: 'FCTHTZ', checked: false },
              {
                name: 'Télecharger facture  pdf',
                key: 'FCTHTFP',
                checked: false
              },
              {
                name: 'Télecharger facture avoir  pdf',
                key: 'FCTHTFAP',
                checked: false
              },
              {
                name:
                  'Transfert des factures annulées vers interface des factures libres',
                key: 'FCTHTFATFL',
                checked: false
              },
              {
                name: 'Envoyer Email',
                key: 'FCTHTHEM',
                checked: false
              },
              {
                name: 'Ajouter reglèment',
                key: 'FCTHACSAR',
                checked: false
              },
              {
                name: 'Modifier reglèment',
                key: 'FCTHACSUR',
                checked: false
              }
            ]
          }
        ]
      },
      //Factures pixel
      {
        name: 'Factures Pixel',
        key: 'GFPXTH',
        checked: false,
        filters: [
          {
            name: 'Filtre Date début , Date fin',
            key: 'GFPXTHFDF',
            checked: false
          },
          {
            name: 'Filtre Vue à afficher',
            key: 'GFPXTHFVA',
            checked: false
          },
          {
            name: 'Recherche',
            key: 'GFPXTHSE',
            checked: false
          },
          {
            name: 'Filtre Délégateur',
            key: 'GFPXTHFD',
            checked: false
          },
          {
            name: 'Filtre Lot',
            key: 'GFPXTHFL',
            checked: false
          },

        ],
        fonctionalite: [
          {
            name: 'Récupération des factures pixel',
            key: 'GFPXTHFGFP',
            checked: false
          },
          {
            name: 'Export Xls',
            key: 'GFPXTHFEXLS',
            checked: false
          },
          {
            name: 'Export Zip',
            key: 'GFPXTHFEZIP',
            checked: false
          },
          {
            name: 'Visualisation de facture',
            key: 'GFPXTHFVFP',
            checked: false
          },
          {
            name: 'Télechargament de facture',
            key: 'GFPXTHFTFP',
            checked: false
          }
        ],
        colonnes: [
          {
            name: 'Afficher colonne Délégateur / Lot ',
            key: 'GFPXTHACDL',
            checked: false
          },
          {
            name: 'Afficher colonne Vendeur',
            key: 'GFPXTHACV',
            checked: false
          },
          {
            name: 'Afficher colonne Acheteur',
            key: 'GFPXTHACA',
            checked: false
          },
          {
            name: "Afficher colonne N° Facture",
            key: 'GFPXTHACN',
            checked: false
          },
          {
            name: 'Afficher colonne Type de  Facture',
            key: 'GFPXTHACT',
            checked: false
          },
          {
            name: 'Afficher colonne Date',
            key: 'GFPXTHACDD',
            checked: false
          },
          {
            name: 'Afficher colonne Reste à charge ',
            key: 'GFPXTHACRAC',
            checked: false
          },
          {
            name: 'Afficher colonne Montant HT',
            key: 'GFPXTHACMHT',
            checked: false
          },
          // {
          //   name: 'Afficher colonne Base TVA 1',
          //   key: 'GFPXTHACTVA1',
          //   checked: false
          // },
          // {
          //   name: 'Afficher colonne Base TVA 2',
          //   key: 'GFPXTHACTVA2',
          //   checked: false
          // },
          // {
          //   name: 'Afficher colonne Base TVA 3',
          //   key: 'GFPXTHACTVA3',
          //   checked: false
          // },
          // {
          //   name: 'Afficher colonne Total TVA 1',
          //   key: 'GFPXTHACTTVA1',
          //   checked: false
          // },
          // {
          //   name: 'Afficher colonne Total TVA 2',
          //   key: 'GFPXTHACTTVA2',
          //   checked: false
          // },
          // {
          //   name: 'Afficher colonne Total TVA 3',
          //   key: 'GFPXTHACTTVA3',
          //   checked: false
          // },
          {
            name: 'Afficher colonne TVA',
            key: 'GFPXTHACTTVAG',
            checked: false
          }, {
            name: 'Afficher colonne Montant TTC',
            key: 'GFPXTHACTTCC',
            checked: false
          },

        ],
      },
      //SETTING
      {
        name: 'Paramètres',
        key: 'PARM',
        checked: false,
        filters: [],
        colonnes: [],
        fonctionalite: [],
        interfaces: [
          {
            name: 'Gestion fiche',
            key: 'GFTH',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Modification',
                key: 'GFTHMO',
                checked: false
              }
            ]
          },
          // {
          //   name: 'Répartition des zones',
          //   key: 'RZTH',
          //   checked: false,
          //   filters: [],
          //   colonnes: [],
          //   fonctionalite: [
          //     {
          //       name: 'Ajouter',
          //       key: 'RZTHA',
          //       checked: false
          //     },
          //     {
          //       name: 'Supprimer',
          //       key: 'RZTHS',
          //       checked: false
          //     }
          //   ]
          // },
          {
            name: 'Calendrier Th',
            key: 'CTTH',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter année',
                key: 'CTTHAA',
                checked: false
              },
              {
                name: 'Visualisation',
                key: 'CTTHV',
                checked: false
              },
              {
                name: 'Ajouter numéro dépôt',
                key: 'CTTHAND',
                checked: false
              },
              {
                name: 'Supprimer la dépôt',
                key: 'CTTHSLD',
                checked: false
              },
              {
                name: 'Déplacer la semaine',
                key: 'CTTHDLS',
                checked: false
              }
            ]
          },

          {
            name: 'Gestion des sociétés',
            key: 'GS',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter société',
                key: 'GSAS',
                checked: false
              },
              {
                name: 'Modifier société',
                key: 'GSMS',
                checked: false
              },

              {
                name: 'Supprimer société',
                key: 'GSSS',
                checked: false
              },
              {
                name: 'Dupliquer société',
                key: 'GSDS',
                checked: false
              },
              {
                name: 'Gestion des séquences',
                key: 'GSGS',
                checked: false
              },
              {
                name: 'Gestion des ribs',
                key: 'GSGR',
                checked: false
              },
              {
                name: 'Gestion des Extra Mails',
                key: 'GSGEM',
                checked: false
              },
              {
                name: 'Liste de filiale pour master',
                key: 'GSLFPM',
                checked: false
              },
              {
                name: 'Gestion Pseudo',
                key: 'GSGP',
                checked: false
              },
              {
                name: 'Gestion société factures',
                key: 'GSGSF',
                checked: false
              },
              {
                name: 'Exporter la liste des sociétés',
                key: 'GSGSELS',
                checked: false
              },
              {
                name: 'Gestion de plan tiers',
                key: 'GSGDPT',
                checked: false
              },
            ]
          },
          {
            name: 'Gestion des clients passager',
            key: 'GCP',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter client passage',
                key: 'GACP',
                checked: false
              },
              {
                name: 'Modifier client passage',
                key: 'GMCP',
                checked: false
              },
              {
                name: 'Supprimer client passage',
                key: 'GSCP',
                checked: false
              },
              {
                name: 'Transfert du client vers la gestion des sociétés',
                key: 'GTCP',
                checked: false
              },
              {
                name: 'Rechercher client passage',
                key: 'GRCP',
                checked: false
              }
            ]
          },
          {
            name: 'Gérer les configurations',
            key: 'GLCTH',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter une configuration',
                key: 'GLCTHAC',
                checked: false
              },
              {
                name: 'Modifier une configuration',
                key: 'GLCTHMC',
                checked: false
              },
              {
                name: 'Dupliquer une configuration',
                key: 'GLCTHDC',
                checked: false
              },
              {
                name: 'Verouiller une configuration',
                key: 'GLCTHVC',
                checked: false
              }
            ]
          },
          // {
          //   name: 'Gestion des familles',
          //   key: 'GDFC',
          //   checked: false,
          //   filters: [],
          //   colonnes: [],
          //   fonctionalite: [
          //     {
          //       name: 'Ajouter une famille',
          //       key: 'GDFCAUF',
          //       checked: false
          //     },
          //     {
          //       name: 'Modifier une famille',
          //       key: 'GDFCMUF',
          //       checked: false
          //     },
          //     {
          //       name: 'Verouiller une famille',
          //       key: 'GDFCVUF',
          //       checked: false
          //     }
          //   ]
          // },
          {
            name: 'Gestion des organismes',
            key: 'GOTH',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter un organisme',
                key: 'GOTHAO',
                checked: false
              },
              {
                name: 'Modifier organisme',
                key: 'GOTHMO',
                checked: false
              },

              {
                name: 'Supprimer organisme',
                key: 'GOTHSO',
                checked: false
              },
              {
                name: 'Dupliquer organisme',
                key: 'GOTHDO',
                checked: false
              },

              {
                name: 'Verouiller organisme',
                key: 'GOTHVO',
                checked: false
              },
              {
                name: 'Ajouter un paramètre spécifique organisme',
                key: 'GOTHAPSO',
                checked: false
              },
              {
                name: 'Modifier un paramètre spécifique organisme',
                key: 'GOTHMPSO',
                checked: false
              },
              {
                name: 'Supprimer un paramètre spécifique organisme',
                key: 'GOTHMSPSO',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des templates',
            key: 'GTTH',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter une Template',
                key: 'GTTHAT',
                checked: false
              },
              {
                name: 'Modifier template',
                key: 'GTTHMT',
                checked: false
              },
              {
                name: 'Supprimer template',
                key: 'GTTHST',
                checked: false
              },
              {
                name: 'Visualiser template',
                key: 'GTTHVT',
                checked: false
              },
              {
                name: 'Table dynamique template',
                key: 'GTTHTDT',
                checked: false
              },
              {
                name: 'Ajouter catégorie',
                key: 'GTTHAC',
                checked: false
              },
              {
                name: 'Renommer catégorie',
                key: 'GTTHRC',
                checked: false
              },
              {
                name: 'Supprimer catégorie',
                key: 'GTTHSC',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des utilisateurs',
            key: 'GUTH',
            checked: false,
            filters: [
              {
                name: 'Chercher un utilisateur',
                key: 'GUHTCU',
                checked: false
              }
            ],
            colonnes: [
              {
                name: 'Afficher colonne Nom',
                key: 'GUHTACN',
                checked: false
              },
              {
                name: 'Afficher colonne E-mail',
                key: 'GUHTACEM',
                checked: false
              },
              {
                name: 'Afficher colonne Type',
                key: 'GUHTACTY',
                checked: false
              },
              {
                name: 'Afficher colonne Télephone',
                key: 'GUHTACTT',
                checked: false
              }
            ],
            fonctionalite: [
              {
                name: 'Ajouter un utilisateur',
                key: 'GUTHAOU',
                checked: false
              },
              {
                name: 'Modifier utilisateur',
                key: 'GUTHMU',
                checked: false
              },
              {
                name: 'Supprimer utilisateur',
                key: 'GUTHSU',
                checked: false
              },
              {
                name: 'Restaurer utilisateur',
                key: 'GUTHRU',
                checked: false
              },
              {
                name: 'Afficher la liste des utilisateurs supprimés',
                key: 'GUHTALUS',
                checked: false
              },
              {
                name: 'Afficher la liste des utilisateurs',
                key: 'GUHTALU',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des permissions',
            key: 'GPHT',
            checked: false,
            filters: [
              // {
              //   name: 'Chercher un utilisateur',
              //   key: 'GPHTCU',
              //   checked: false
              // }
            ],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Assigner une permission à un utilisateur séléctionné',
                key: 'GPHTAPUS',
                checked: false
              }
            ]
          },
          {
            name: 'Gestion des profils',
            key: 'GPRHT',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter un profil',
                key: 'GUTHAOP',
                checked: false
              },
              {
                name: 'Assigner une permission à un profil séléctionné',
                key: 'GPRHTAPPS',
                checked: false
              },
              {
                name: 'Bloquer / Débloquer un profil',
                key: 'GPRHTBDP',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des familles',
            key: 'GPHTF',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Assigner une famille à un profil séléctionné',
                key: 'GPHTAFTP',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des sociéte à traiter',
            key: 'GPATTH',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter',
                key: 'GPATADDTH',
                checked: false
              },
              {
                name: 'Modifier',
                key: 'GPATUPDTH',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des catégories Facture libre',
            key: 'GCATTH',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter catégorie',
                key: 'GCATTHAC',
                checked: false
              },
              {
                name: 'Modifier catégorie',
                key: 'GCATTHMC',
                checked: false
              },
              {
                name: 'Supprimer catégorie',
                key: 'GCATTHSC',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion Type Societe',
            key: 'GTSFL',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter Type Societe',
                key: 'GTSFLA',
                checked: false
              },
              {
                name: 'Modifier Type Societe',
                key: 'GTSFLM',
                checked: false
              },
              {
                name: 'Supprimer Type Societe',
                key: 'GTSFLS',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des configurations',
            key: 'GCM',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter Configuration Mensuel',
                key: 'GCMACM',
                checked: false
              },
              {
                name: 'Modifier Configuration Mensuel',
                key: 'GCMMCM',
                checked: false
              },
              {
                name: 'Supprimer Configuration Mensuel',
                key: 'GCMSCM',
                checked: false
              },
              {
                name: 'Dupliquer Configuration Mensuel',
                key: 'GCMDCM',
                checked: false
              },
              {
                name: 'Configurations par biens',
                key: 'GCMCPB',
                checked: false
              },
              {
                name: 'Configurations par services',
                key: 'GCMCPS',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des produits Facture libre',
            key: 'GPTH',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter produit',
                key: 'GPTHAP',
                checked: false
              },
              {
                name: 'Modifier produit',
                key: 'GPTHMP',
                checked: false
              },
              {
                name: 'Supprimer produit',
                key: 'GPTHSP',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des vues',
            key: 'GVTH',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter Vue',
                key: 'GVTHGVTH',
                checked: false
              },
              {
                name: 'Modifier Vue',
                key: 'GVTHMC',
                checked: false
              },
              {
                name: 'Renommer Vue',
                key: 'GVTHRV',
                checked: false
              },
              {
                name: 'Supprimer Vue',
                key: 'GVTHSV',
                checked: false
              },
              {
                name: 'Bloquer / Débloquer Vue',
                key: 'GVTHBDV',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion les modèles exportation',
            key: 'GMETH',
            checked: false,
            filters: [],
            colonnes: [
              {
                name: 'Afficher colonne Nom',
                key: 'GMETHACN',
                checked: false
              },
              {
                name: 'Afficher colonne créateur ',
                key: 'GMETHACR',
                checked: false
              },
              {
                name: 'Afficher colonne statut',
                key: 'GMETHACS',
                checked: false
              }
            ],
            fonctionalite: [
              {
                name: "Ajouter un modèle d'export",
                key: 'GMETHAME',
                checked: false
              },
              {
                name: "Visualiser modèle d'export",
                key: 'GMETHVME',
                checked: false
              },
              {
                name: "Modifier modèle d'export",
                key: 'GMETHMDE',
                checked: false
              },
              {
                name: "Supprimer un modèle d'export",
                key: 'GMETHSME',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des biens',
            key: 'GBHT',
            checked: false,
            filters: [
              {
                name: 'Chercher un bien',
                key: 'GBHTCB',
                checked: false
              },
              {
                name: 'Exporter Liste des biens',
                key: 'GBHTELB',
                checked: false
              }
            ],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter un bien',
                key: 'GBHTAB',
                checked: false
              },
              {
                name: 'Modifier un Bien',
                key: 'GBHTMB',
                checked: false
              },
              {
                name: 'Dupliquer un Bien',
                key: 'GBHTDB',
                checked: false
              },
              {
                name: 'Bloquer / Débloquer un Bien',
                key: 'GBHTBDB',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion des services',
            key: 'GSHT',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter un service',
                key: 'GSHTAS',
                checked: false
              },
              {
                name: 'Modifier un service',
                key: 'GSHTMS',
                checked: false
              },
              {
                name: 'Supprimer un service',
                key: 'GSHTSS',
                checked: false
              },
              {
                name: 'Dupliquer un service',
                key: 'GSHTDS',
                checked: false
              }
            ],
            interfaces: []
          },
          {
            name: 'Gestion types paiement',
            key: 'GTPHT',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [

              {
                name: 'Ajouter un type du paiement',
                key: 'GTRHTATR',
                checked: false
              },
              {
                name: 'Modifier un type  du paiement',
                key: 'GTRHTMTR',
                checked: false
              },
              {
                name: 'Supprimer un type du paiement',
                key: 'GTRHTSTR',
                checked: false
              }

            ]
          },
          {
            name: 'Gestion conditions paiement',
            key: 'GCPHT',
            checked: false,
            filters: [],
            colonnes: [],
            fonctionalite: [
              {
                name: 'Ajouter une condition du paiement',
                key: 'GTRHTACP',
                checked: false
              },
              {
                name: 'Modifier une condition du paiement',
                key: 'GTRHTMCP',
                checked: false
              },
              {
                name: 'Supprimer une condition du paiement',
                key: 'GTRHTSCP',
                checked: false
              }
            ]
          }
        ]
      },
      //Frais
      {
        name: 'Gestion des frais',
        key: 'GDFTH',
        checked: false,
        filters: [],
        colonnes: [],
        fonctionalite: [],
        interfaces: [
          {
            name: 'Simulation facture',
            key: 'SFTH',
            checked: false,
            filters: [
              {
                name: 'Recherche',
                key: 'SFTHCS',
                checked: false
              },
              {
                name: 'Filter :  Type de date , Date début , Date fin',
                key: 'SFTHFTDDDDF',
                checked: false
              },
              {
                name: 'Filter Master filiale',
                key: 'SFTHFMF',
                checked: false
              },
              {
                name: 'Filter Société',
                key: 'SFTHFV',
                checked: false
              },
              {
                name: 'Filter Client',
                key: 'SFTHFCL',
                checked: false
              },
              {
                name: 'Filter Famille',
                key: 'SFTHFFS',
                checked: false
              },
              {
                name: 'Filter Lot',
                key: 'SFTHFL',
                checked: false
              },
              {
                name: 'Filter Confirmation',
                key: 'SFTHFCO',
                checked: false
              },
              {
                name: 'Filter Statut',
                key: 'SFTHFSF',
                checked: false
              }
            ],
            fonctionalite: [
              {
                name: 'Regénérer les facture',
                key: 'SFTHRD',
                checked: false
              },
              {
                name: 'Confirmer/Déconfirmer les dossiers',
                key: 'SFTHCDD',
                checked: false
              },
              {
                name: 'Verrouillage des factures',
                key: 'SFTHMEC',
                checked: false
              },
              {
                name: 'Télécharger simulation xls',
                key: 'SFTHSDX',
                checked: false
              },
              {
                name: 'Modifier le coefficient',
                key: 'SFTHMC',
                checked: false
              }
            ],
            colonnes: [
              {
                name: 'Afficher colonne Nom et Prénom',
                key: 'SFTHCNEP',
                checked: false
              },
              {
                name: 'Afficher colonne Filiale Installateur',
                key: 'SFTHACFI',
                checked: false
              },
              {
                name: 'Afficher colonne Régie',
                key: 'SFTHACR',
                checked: false
              },
              {
                name: 'Afficher colonne Type isolation',
                key: 'SFTHACTI',
                checked: false
              },
              {
                name: 'Afficher colonne Montant min',
                key: 'SFTHACMM',
                checked: false
              },
              {
                name: 'Afficher colonne Montant HT Dossier',
                key: 'SFTHACMHTD',
                checked: false
              },
              {
                name: 'Afficher colonne Date de dépôt',
                key: 'SFTHACDDD',
                checked: false
              },
              {
                name: 'Afficher colonne Statue de paiement',
                key: 'SFTHACSDP',
                checked: false
              },
              {
                name: 'Afficher colonne Date de paiement',
                key: 'SFTHACDDP',
                checked: false
              },
              {
                name: 'Afficher colonne Type de document',
                key: 'SFTHACTDD',
                checked: false
              },
              {
                name: 'Afficher colonne Numéro de facture',
                key: 'SFTHACNDF',
                checked: false
              },
              {
                name: 'Afficher colonne Vendeur',
                key: 'SFTHACV',
                checked: false
              },
              {
                name: 'Afficher colonne Client',
                key: 'SFTHACCL',
                checked: false
              },
              {
                name: 'Afficher colonne Objet',
                key: 'SFTHACOB',
                checked: false
              },
              {
                name: 'Afficher colonne Montant HT',
                key: 'SFTHACMHT',
                checked: false
              },
              {
                name: 'Afficher colonne TVA',
                key: 'SFTHACTVA',
                checked: false
              },
              {
                name: 'Afficher colonne Montant TTC',
                key: 'SFTHACMTTC',
                checked: false
              },
              {
                name: 'Afficher colonne Coefficient',
                key: 'SFTHACCOEF',
                checked: false
              },
              {
                name: 'Afficher colonne Statut de facture',
                key: 'SFTHACSDF',
                checked: false
              },
              {
                name: 'Afficher colonne Date de création de la facture',
                key: 'SFTHACDDCDLF',
                checked: false
              },
              {
                name: 'Afficher colonne Verrouillage',
                key: 'SFTHACVER',
                checked: false
              }
            ]
          },
          {
            name: 'Gestion global des frais',
            key: 'GGDFTH',
            checked: false,
            filters: [
              {
                name: 'Filter :  Type de date , Date début , Date fin',
                key: 'GGDFTHFTDDDDF',
                checked: false
              },
              {
                name: 'Filter Master filiale',
                key: 'GGDFTHFMF',
                checked: false
              },
              {
                name: 'Filter Vendeur',
                key: 'GGDFTHFV',
                checked: false
              },
              {
                name: 'Filter Client',
                key: 'GGDFTHFCL',
                checked: false
              },
              {
                name: 'Filter Famille',
                key: 'GGDFTHFLL',
                checked: false
              },
              {
                name: 'Filter Statut',
                key: 'GGDFTHSTAT',
                checked: false
              },
              {
                name: 'Filter Statut installateur',
                key: 'GGDFTHSTATIN',
                checked: false
              },
              {
                name: 'Recherche',
                key: 'GGDFTHCS',
                checked: false
              }
            ],
            colonnes: [
              {
                name: 'Afficher colonne Numéro de dossier',
                key: 'GGDFTHACNDD',
                checked: false
              },
              {
                name: 'Afficher colonne Nom et prénom',
                key: 'GGDFTHACNEP',
                checked: false
              },
              {
                name: 'Afficher colonne Montant min',
                key: 'GGDFTHACMM',
                checked: false
              },
              {
                name: 'Afficher colonne Montant HT dossier',
                key: 'GGDFTHACMHTD',
                checked: false
              },

              {
                name: 'Afficher colonne Numéro de facture',
                key: 'GGDFTHCNDF',
                checked: false
              },
              {
                name: 'Afficher colonne Numéro de facture avoir',
                key: 'GGDFTHCNFA',
                checked: false
              },
              {
                name: 'Afficher colonne Vendeur',
                key: 'GGDFTHCV',
                checked: false
              },
              {
                name: 'Afficher colonne Client',
                key: 'GGDFTHACCL',
                checked: false
              },
              {
                name: 'Afficher colonne Montant HT',
                key: 'GGDFTHACMHT',
                checked: false
              },
              {
                name: 'Afficher colonne TVA',
                key: 'GGDFTHACTVA',
                checked: false
              },

              {
                name: 'Afficher colonne Montant TTC',
                key: 'GGDFTHACMTTC',
                checked: false
              },
              {
                name: 'Afficher colonne Date de facture',
                key: 'GGDFTHCDDF',
                checked: false
              },
              {
                name: 'Afficher colonne Reste à payer',
                key: 'GGDFTHCRAP',
                checked: false
              },
              {
                name: 'Afficher colonne Statut de facture',
                key: 'GGDFTHCSDF',
                checked: false
              },
              {
                name: 'Afficher colonne Statut installateur',
                key: 'GGDFTHCSI',
                checked: false
              },

              // {
              //   name: 'Afficher colonne Type de paiement',
              //   key: 'GGDFTHCTDP',
              //   checked: false
              // },
              {
                name: 'Afficher colonne date de paiement',
                key: 'GGDFTHCDP',
                checked: false
              },
              {
                name: 'Afficher colonne reglement',
                key: 'GGDFTHCRG',
                checked: false
              },

              {
                name: "Afficher colonne Date d'envoie",
                key: 'GGDFTHACDEM',
                checked: false
              },
              {
                name: 'Afficher colonne Numéro de lot',
                key: 'GGDFTHCNL',
                checked: false
              },
              {
                name: 'Afficher colonne historique',
                key: 'GGDFTHCH',
                checked: false
              },
              {
                name: 'Afficher colonne Coefficient',
                key: 'GGDFTHCCF',
                checked: false
              }
            ],
            fonctionalite: [
              {
                name: 'Afficher toutes les factures',
                key: 'VTGGDF',
                checked: false
              },
              {
                name: 'Générer des avoirs partielle',
                key: 'GGDFTHGDAP',
                checked: false
              },
              {
                name: 'Afficher block total',
                key: 'GGDFTHABT',
                checked: false
              },
              {
                name: 'Confirmation multiple de facture',
                key: 'GGDFTHCMF',
                checked: false
              },

              {
                name: 'Genération des factures avoir',
                key: 'GGDFTHGFA',
                checked: false
              },
              
              // {
              //   name: 'Modification de statue  par selection',
              //   key: 'GGDFTHMSPS',
              //   checked: false
              // },
              // {
              //   name: 'Modification  de statue installateur par selection',
              //   key: 'GGDFTHMSIPS',
              //   checked: false
              // },
              {
                name: 'Modifier le coefficient',
                key: 'GGDFTHMCF',
                checked: false
              },
              {
                name: 'Validation de facture',
                key: 'GGDFTHVF',
                checked: false
              },
              {
                name: 'Modifier le type de paiement',
                key: 'GGDFTHMTP',
                checked: false
              },
              // {
              //   name: 'Modifier le statur par facture',
              //   key: 'GGDFTHMSPF',
              //   checked: false
              // },
              // {
              //   name: 'Modifier le statut installateur par facture',
              //   key: 'GGDFTHMSIPF',
              //   checked: false
              // },
              { name: 'Télecharger XLS', key: 'GGDFTHEXLS', checked: false },
              { name: 'Télecharger ZIP', key: 'GGDFTHEZP', checked: false },
              { name: 'Envoi mail', key: 'GGDFTHEM', checked: false },
              {
                name: 'Télecharger facture  pdf',
                key: 'GGDFTHEFP',
                checked: false
              },
              {
                name:
                  'Transfert des factures annulées vers interface des factures libres',
                key: 'GGDFTHTFAIFL',
                checked: false
              },
              {
                name: 'Ajouter règlement',
                key: 'GGDFTHAR',
                checked: false
              },
              {
                name: 'Modifier règlement',
                key: 'GGDFTHUR',
                checked: false
              },
              {
                name: 'Télécharger Facture Avoir',
                key: 'GGDFTHTFA',
                checked: false
              },
              {
                name: 'Afficher Facture',
                key: 'GGDFTHTAF',
                checked: false
              },
              {
                name: 'Afficher Facture  Avoir',
                key: 'GGDFTHTAFA',
                checked: false
              },
              {
                name: 'Supprimer facture proforma',
                key: 'GGDFTHDFA',
                checked: false
              },
              {
                name: "Date création de l'avoir",
                key: 'SFTHDCA',
                checked: false
              },
              {
                name: 'Validation de facture',
                key: 'SFTHVF',
                checked: false
              },
            ]
          }

          // {
          // name: 'Gestion master filiale 2.0',
          // key: 'GMFTH2',
          // checked: false,
          // filters: [
          // {
          //       name: 'Filter :Date début , Date fin',
          //       key: 'GMFTH2DDF',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Société',
          //       key: 'GMFTH2FS',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Client',
          //       key: 'GMFTH2FC',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Statut',
          //       key: 'GMFTH2FST',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Statut client',
          //       key: 'GMFTH2FSC',
          //       checked: false
          //     },
          //     {
          //       name: 'Recherche',
          //       key: 'GMFTH2R',
          //       checked: false
          //     },
          // ],
          // colonnes: [
          // {
          //       name: 'Afficher colonne client',
          //       key: 'GMFTH2ACC',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne société',
          //       key: 'GMFTH2ACS',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Montant HT',
          //       key: 'GMFTH2ACMH',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne TVA',
          //       key: 'GMFTH2ACT',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Montant TTC',
          //       key: 'GMFTH2TTC',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Numéro de facture',
          //       key: 'GMFTH2ACNF',
          //       checked: false
          //     },
          //     // {
          //     //   name: 'Afficher colonne Numéro de facture avoir',
          //     //   key: 'GMFTH2ACNFA',
          //     //   checked: false
          //     // },
          //     {
          //       name: 'Afficher colonne statut de facture',
          //       key: 'GMFTH2ACSF',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne statut client',
          //       key: 'GMFTH2ACSC',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Date de facture',
          //       key: 'GMFTH2ACD',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne mois de facture',
          //       key: 'GMFTH2ACMF',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne date de paiement',
          //       key: 'GMFTH2CDP',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne reglement',
          //       key: 'GMFTH2CRG',
          //       checked: false
          //     },
          // ],
          // fonctionalite: [
          // {
          //       name: 'Afficher toutes les factures',
          //       key: 'VTFMaster',
          //       checked: false
          //     },
          //
          //     {
          //       name: 'Ajouter Facture',
          //       key: 'GMFTH2AF',
          //       checked: false
          //     },
          //
          //     {
          //       name: 'Afficher Block total',
          //       key: 'GMFTH2ABT',
          //       checked: false
          //     },
          //     {
          //       name: 'Validation multiple des factures',
          //       key: 'GMFTH2VMF',
          //       checked: false
          //     },
          //     {
          //       name: 'Genération des factures avoir',
          //       key: 'GMFTH2GFA',
          //       checked: false
          //     },
          //     {
          //       name: 'Validation de facture',
          //       key: 'GMFTH2VF',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher la facture',
          //       key: 'GMFTH2AFM',
          //       checked: false
          //     },
          //     // {
          //     //   name: 'Modifier le statut par facture',
          //     //   key: 'GMFTH2SF',
          //     //   checked: false
          //     // },
          //     {
          //       name: 'Modifier le statut client par facture',
          //       key: 'GMFTH2MSCF',
          //       checked: false
          //     },
          //     // {
          //     //   name: 'Modification Multiple Statuts factures',
          //     //   key: 'GMFTH2MMSF',
          //     //   checked: false
          //     // },
          //     {
          //       name: 'Modification Multiple Statut clients factures',
          //       key: 'GMFTH2MMSCF',
          //       checked: false
          //     },
          //     { name: 'Télecharger XLS', key: 'GMFTH2TX', checked: false },
          //     { name: 'Télecharger ZIP', key: 'GMFTH2TZ', checked: false },
          //     {
          //       name: 'Télecharger facture  pdf',
          //       key: 'GMFTH2TFP',
          //       checked: false
          //     },
          //     {
          //       name: 'Télecharger facture avoir  pdf',
          //       key: 'GMFTH2TFAP',
          //       checked: false
          //     },
          //     {
          //       name:
          //         'Transfert des factures annulées vers interface des factures libres',
          //       key: 'GMFTH2TFATFL',
          //       checked: false
          //     },
          //     { name: 'Envoi mail', key: 'GMFTH2EM', checked: false },
          //     {
          //       name: 'Ajouter reglement',
          //       key: 'GMFTH2AR',
          //       checked: false
          //     }],
          //   ]
          // }
          // {
          //   name: 'Gestion Compta',
          //   key: 'GCTH',
          //   checked: false,
          //   permissions: [
          //     {
          //       name: 'Filter Mois',
          //       key: 'GCTHFM',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Master filiale',
          //       key: 'GCTHFMF',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Filiale installateur',
          //       key: 'GCTHFFI',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Statut',
          //       key: 'GCTHFS',
          //       checked: false
          //     },
          //     {
          //       name: 'Recherche',
          //       key: 'GCTHRE',
          //       checked: false
          //     },
          //     { name: 'Filter année', key: 'GCTHFA', checked: false },
          //     { name: 'Afficher colonne Mois', key: 'GCTHACM', checked: false },
          //     {
          //       name: 'Afficher colonne Filiale installateur',
          //       key: 'GCTHACMFI',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Montant HT GE COMPTA',
          //       key: 'GCTHACMHT',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Montant TTC GE COMPTA',
          //       key: 'GCTHACMTTC',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne N° Facture',
          //       key: 'GCTHACNF',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Statut ',
          //       key: 'GCTHACS',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Statut de paiement installateur',
          //       key: 'GCTHACSI',
          //       checked: false
          //     },
          //     { name: 'Afficher colonne Date', key: 'GCTHACD', checked: false },
          //     {
          //       name: 'Afficher colonne Action',
          //       key: 'GCTHACA',
          //       checked: false
          //     },
          //     // { name: 'Afficher block total', key: 'GCTHABT', checked: false },
          //     {
          //       name: 'Validation globale de facture',
          //       key: 'GCTHVGDF',
          //       checked: false
          //     },
          //     {
          //       name: 'Générer des factures avoirs',
          //       key: 'GCTHGDFA',
          //       checked: false
          //     },
          //     { name: 'Télecharger ZIP', key: 'GCTHTZIP', checked: false },
          //     { name: 'Télecharger XLS', key: 'GCTHTXLS', checked: false },
          //     { name: 'Envoi mail', key: 'GCTHEM', checked: false },
          //     { name: 'Créer des factures', key: 'GCTHCDF', checked: false },
          //     { name: 'Valider une facture', key: 'GCTHVUF', checked: false },
          //     { name: 'Télecharger facture', key: 'GCTHTUF', checked: false },
          //     {
          //       name: 'Télecharger facture avoir',
          //       key: 'GCTHTFA',
          //       checked: false
          //     },
          //     { name: 'Modifier statut', key: 'GCTHMS', checked: false },
          //     {
          //       name: 'Modifier statut de paiement installateur',
          //       key: 'GCTHMSI',
          //       checked: false
          //     },
          //     { name: 'Supprimer facture', key: 'GCTHSF', checked: false },
          //     { name: 'Modifier montant', key: 'GCTHMMT', checked: false }
          //   ]
          // },
          // {
          //   name: 'Gestion RH',
          //   key: 'GRHTH',
          //   checked: false,
          //   permissions: [
          //     { name: 'Filtre Années', key: 'GRHTHFA', checked: false },
          //     {
          //       name: 'Filter Mois',
          //       key: 'GRHTHFM',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Master filiale',
          //       key: 'GRHTHFMF',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Filiale installateur',
          //       key: 'GRHTHFFI',
          //       checked: false
          //     },
          //     {
          //       name: 'Filter Statut',
          //       key: 'GRHTHFS',
          //       checked: false
          //     },
          //     {
          //       name: 'Recherche',
          //       key: 'GRHTHRE',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Mois',
          //       key: 'GRHTHACM',
          //       checked: false,
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher la colonne Filiale Installateur',
          //       key: 'GRHTHACFI',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Montant HT GE RH',
          //       key: 'GRHTHACMHT',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Montant TTC GE RH',
          //       key: 'GRHTHACMTTC',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne N° Facture',
          //       key: 'GRHTHACNF',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Statut ',
          //       key: 'GRHTHACS',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Action ',
          //       key: 'GRHTHACA',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Statut de paiement installateur',
          //       key: 'GRHTHACSI',
          //       checked: false
          //     },
          //     {
          //       name: 'Afficher colonne Date',
          //       key: 'GRHTHACD',
          //       checked: false
          //     },
          //     // { name: 'Afficher block total', key: 'GRHTHABT', checked: false },
          //     {
          //       name: 'Validation globale de facture',
          //       key: 'GRHTHVGDF',
          //       checked: false
          //     },
          //     {
          //       name: 'Générer des factures avoirs',
          //       key: 'GRHTHGDFA',
          //       checked: false
          //     },
          //     { name: 'Télecharger ZIP', key: 'GRHTHTZIP', checked: false },
          //     { name: 'Télecharger XLS', key: 'GRHTHTXLS', checked: false },
          //     { name: 'Envoi mail', key: 'GRHTHEM', checked: false },
          //     { name: 'Créer des factures', key: 'GRHTHCDF', checked: false },
          //     { name: 'Valider une facture', key: 'GRHTHVUF', checked: false },
          //     { name: 'Télecharger facture', key: 'GRHTHTUF', checked: false },
          //     {
          //       name: 'Télecharger facture avoir',
          //       key: 'GRHTHTFA',
          //       checked: false
          //     },
          //     { name: 'Modifier statut', key: 'GRHTHMS', checked: false },
          //     {
          //       name: 'Modifier statut de paiement installateur',
          //       key: 'GRHTHMSI',
          //       checked: false
          //     },
          //     {
          //       name: 'Supprimer Facture',
          //       key: 'GRHTHSF',
          //       checked: false
          //     },
          //     {
          //       name: 'Modifier date',
          //       key: 'GRHTHMD',
          //       checked: false
          //     },
          //     { name: 'Modifier montant', key: 'GRHTMM', checked: false }
          //   ]
          // }
        ]
      },
      // Mensuell
      {
        name: 'Gestion factures mensuelles',
        key: 'GFMTH',
        checked: false,
        filters: [
          {
            name: 'Filter :Date début , Date fin',
            key: 'GFMTHDDF',
            checked: false
          },
          {
            name: 'Filter Année / Mois',
            key: 'GFMTHFAM',
            checked: false
          },
          {
            name: 'Filter Société',
            key: 'GFMTHFS',
            checked: false
          },
          {
            name: 'Filter Client',
            key: 'GFMTHFC',
            checked: false
          },
          {
            name: 'Filter Statut',
            key: 'GFMTHFST',
            checked: false
          },
          {
            name: 'Filter Statut client',
            key: 'GFMTHFSC',
            checked: false
          },
          {
            name: 'Recherche',
            key: 'GFMTHR',
            checked: false
          }
        ],
        colonnes: [
          {
            name: 'Afficher colonne client',
            key: 'GFMTHACC',
            checked: false
          },
          {
            name: 'Afficher colonne société',
            key: 'GFMTHACS',
            checked: false
          },
          {
            name: 'Afficher colonne Montant HT',
            key: 'GFMTHACMH',
            checked: false
          },
          {
            name: 'Afficher colonne TVA',
            key: 'GFMTHACT',
            checked: false
          },

          {
            name: 'Afficher colonne Montant TTC',
            key: 'GFMTHTTC',
            checked: false
          },
          {
            name: 'Afficher colonne Numéro de facture',
            key: 'GFMTHACNF',
            checked: false
          },
          {
            name: 'Afficher colonne Numéro de facture avoir',
            key: 'GFMTHACNFA',
            checked: false
          },
          {
            name: 'Modifier le montant de facture',
            key: 'GFMTHMMF',
            checked: false
          },
          {
            name: 'Afficher colonne Reste à payer',
            key: 'GFMTHACRAP',
            checked: false
          },
          {
            name: 'Afficher colonne statut de facture',
            key: 'GFMTHACSF',
            checked: false
          },
          {
            name: 'Afficher colonne statut client',
            key: 'GFMTHACSC',
            checked: false
          },
          {
            name: 'Afficher colonne Date de facture',
            key: 'GFMTHACD',
            checked: false
          },
          {
            name: 'Afficher colonne mois de facture',
            key: 'GFMTHACMF',
            checked: false
          },
          {
            name: 'Afficher colonne date de paiement',
            key: 'GFMTHCDP',
            checked: false
          },
          {
            name: 'Afficher colonne reglement',
            key: 'GFMTHCRG',
            checked: false
          },
          {
            name: 'Afficher colonne Historique',
            key: 'GFMTHCH',
            checked: false
          }
        ],
        fonctionalite: [
          {
            name: 'Ajouter Facture',
            key: 'GFMTHAF',
            checked: false
          },

          {
            name: 'Afficher Block total',
            key: 'GFMTHABT',
            checked: false
          },
          {
            name: 'Validation multiple des factures',
            key: 'GFMTHVMF',
            checked: false
          },
          {
            name: 'Genération des factures avoir',
            key: 'GFMTHGFA',
            checked: false
          },

          {
            name: 'Validation de facture',
            key: 'GFMTHVF',
            checked: false
          },
          {
            name: 'Afficher la facture',
            key: 'GFMTHAFM',
            checked: false
          },
          {
            name: 'Générer Avoir Partiel',
            key: 'GFMTHGAP',
            checked: false
          },
          {
            name: 'Télécharger Facture Avoir',
            key: 'GFMFTHTFA',
            checked: false
          },
          // {
          //   name: 'Modifier le statut par facture',
          //   key: 'GFMTHSF',
          //   checked: false
          // },

          // {
          //   name: 'Modifier le statut client par facture',
          //   key: 'GFMTHMSCF',
          //   checked: false
          // },
          // {
          //   name: 'Modification Multiple Statuts factures',
          //   key: 'GFMTHMMSF',
          //   checked: false
          // },
          // {
          //   name: 'Modification Multiple Statut clients factures',
          //   key: 'GFMTHMMSCF',
          //   checked: false
          // },
          { name: 'Télecharger XLS', key: 'GFMTHTX', checked: false },
          { name: 'Télecharger ZIP', key: 'GFMTHTZ', checked: false },
          {
            name: 'Télecharger facture  pdf',
            key: 'GFMTHTFP',
            checked: false
          },
          {
            name: 'Télecharger facture avoir  pdf',
            key: 'GFMTHTFAP',
            checked: false
          },
          {
            name:
              'Transfert des factures annulées vers interface des factures libres',
            key: 'GFMTHTFATFL',
            checked: false
          },
          { name: 'Envoi mail', key: 'GFMTHEM', checked: false },
          {
            name: 'Ajouter règlement',
            key: 'GFMTHAR',
            checked: false
          },
          {
            name: 'Modifier règlement',
            key: 'GFMTHUR',
            checked: false
          },
          {
            name: "Date création de l'avoir",
            key: 'GFMTHDCA',
            checked: false
          }
        ],
        interfaces: []
      },
      // Master 
      {
        name: 'Gestion master filiale',
        key: 'GMFTH',
        checked: false,
        filters: [],
        colonnes: [
          {
            name: 'Afficher colonne société',
            key: 'GMFTHCSO',
            checked: false
          },
          {
            name: 'Afficher colonne client',
            key: 'GMFTHCCL',
            checked: false
          },
          {
            name: 'Afficher colonne dépôt',
            key: 'GMFTHCDEPT',
            checked: false
          },
          // {
          //   name: 'Afficher la colonne Filiale Installateur',
          //   key: 'GMFTHCFI',
          //   checked: false
          // },
          {
            name: 'Afficher la colonne Numéro de facture',
            key: 'GMFTHCNF',
            checked: false
          },
          {
            name: 'Afficher la colonne Avoir(s)',
            key: 'GMFTHCNFA',
            checked: false
          },
          {
            name: 'Afficher la colonne Date de facture',
            key: 'GMFTHCDF',
            checked: false
          },
          {
            name: 'Afficher la colonne Montant HT',
            key: 'GMFTHCMOHT',
            checked: false
          },
          {
            name: 'Afficher la colonne Montant TVA',
            key: 'GMFTHCTVA',
            checked: false
          },
          {
            name: 'Afficher la colonne Montant TTC',
            key: 'GMFTHCMO',
            checked: false
          },
          {
            name: 'Afficher la colonne Reste à payer',
            key: 'GMFTHCRAP',
            checked: false
          },
          {
            name: 'Afficher la colonne Statut de facture',
            key: 'GMFTHCCSF',
            checked: false
          },
          {
            name: 'Afficher la colonne Statut de paiement installateur',
            key: 'GMFTHCSPI',
            checked: false
          },
          {
            name: 'Afficher colonne date de paiement',
            key: 'GMFTHCDP',
            checked: false
          },
          {
            name: 'Afficher colonne reglement',
            key: 'GMFTHCRG',
            checked: false
          },
          {
            name: 'Afficher colonne Historique',
            key: 'GMFTHCH',
            checked: false
          }
          // {
          //   name: "Afficher la colonne Date d'envoie",
          //   key: 'GMFTHCDE',
          //   checked: false
          // },
          // {
          //   name: 'Afficher la colonne Numéro de lot',
          //   key: 'GMFTHCNL',
          //   checked: false
          // },

          // {
          //   name: 'Afficher la colonne Numéro de dossier',
          //   key: 'GMFTHCND',
          //   checked: false
          // },

          // {
          //   name: 'Afficher la colonne Type de paiement',
          //   key: 'GMFTHCTP',
          //   checked: false
          // },

          // {
          //   name: 'Afficher la colonne Numéro de facture avoir',
          //   key: 'GMFTHCNA',
          //   checked: false
          // },
        ],
        fonctionalite: [
          {
            name: 'Afficher toutes les factures',
            key: 'VTFMF',
            checked: false
          },
          {
            name:
              'Transfert des factures annulées vers interface des factures libres',
            key: 'GMFTHTFATFL',
            checked: false
          },
          { name: 'Ajouter facture', key: 'GMFTHANF', checked: false },
          // {
          //   name: 'Filter :  Type de date',
          //   key: 'GMFTHFTP',
          //   checked: false
          // },
          { name: 'Filter Date début', key: 'GMFTHFDD', checked: false },
          { name: 'Filter Date fin', key: 'GMFTHFDF', checked: false },
          // {
          //   name: 'Filter Master filiale',
          //   key: 'GMFTHFMF',
          //   checked: false
          // },
          { name: 'Filter Société', key: 'GMFTHFF', checked: false },
          { name: 'Filter Client', key: 'GMFTHFC', checked: false },
          // { name: 'Filter Dépôt', key: 'GMFTHFDE', checked: false },
          { name: 'Filter Statut', key: 'GMFTHFS', checked: false },
          { name: 'Filter Statut client', key: 'GMFTHFSC', checked: false },
          { name: 'Recherche', key: 'GMFTHCS', checked: false },
          {
            name: 'Confirmation multiple de facture',
            key: 'GMFTHCMF',
            checked: false
          },
          {
            name: 'Genération des factures avoir total',
            key: 'GMFTHGFA',
            checked: false
          },
          {
            name: 'Genération des factures avoir partial',
            key: 'GMFTHGDAP',
            checked: false
          },
          { name: 'Télecharger XLS', key: 'GMFTHEXLS', checked: false },
          { name: 'Télecharger ZIP', key: 'GMFTHEZP', checked: false },
          { name: 'Envoi mail', key: 'GMFTHEM', checked: false },
          {
            name: 'Ajouter règlement',
            key: 'GMFTHAR',
            checked: false
          },
          {
            name: 'Modifier règlement',
            key: 'GMFTHUR',
            checked: false
          },
          {
            name: 'Modification  de statue installateur par selection',
            key: 'GMFTHMSIPS',
            checked: false
          },
          {
            name: 'Télecharger facture  pdf',
            key: 'GMFTHEFP',
            checked: false
          },
          {
            name: 'Visualisation facture  pdf',
            key: 'GMFTHEVF',
            checked: false
          },
          {
            name: 'Télecharger facture avoir  pdf',
            key: 'GMFTHEFAP',
            checked: false
          },
          {
            name: 'Visualisation Facture Avoir',
            key: 'GMFTHVFA',
            checked: false
          },
          {
            name: 'Validation de facture',
            key: 'GMFTHVF',
            checked: false
          },
          //   name: 'Modifier le montant de facture',
          //   key: 'GMFTHMMF',
          //   checked: false
          // },
          // {
          //   name: 'Modifier le statur par facture',
          //   key: 'GMFTHMSPF',
          //   checked: false
          // },
          {
            name: 'Modifier le statut installateur par facture',
            key: 'GMFTHMSIPF',
            checked: false
          },
          {
            name: 'Afficher block total',
            key: 'GMFTHABT',
            checked: false
          },

          // {
          //   name: 'Modification de statue  par selection',
          //   key: 'GMFTHMSPS',
          //   checked: false
          // },

          // {
          //   name: 'Modifier le type de paiement',
          //   key: 'GMFTHMTP',
          //   checked: false
          // },
          {
            name: "Date création de l'avoir",
            key: 'GMFTHDCA',
            checked: false
          },
          {
            name: 'Validation de facture',
            key: 'GMFTHVFM',
            checked: false
          },
        ],
        interfaces: []
      },
      //comptabilite
      {
        name: 'comptabilité',
        key: 'COTH',
        checked: false,
        filters: [
          { name: 'Filter Date début', key: 'COTHFDD', checked: false },
          { name: 'Filter Date fin', key: 'COTHFDF', checked: false },
          { name: 'Filter Société', key: 'COTHFV', checked: false },
          { name: 'Filter Client', key: 'COTHFA', checked: false },
          { name: 'Filter Famille', key: 'COTHFF', checked: false },
          { name: 'Filter Catégorie', key: 'COTHFC', checked: false },
          { name: 'Filter Statut', key: 'COTHFS', checked: false },
          { name: 'Filter Type', key: 'COTHFT', checked: false },
          { name: 'Filter Comptabilisé', key: 'COTHFCO', checked: false },
          { name: 'Filter Lot', key: 'COTHFL', checked: false },
          { name: 'Recherche', key: 'COTHR', checked: false }
        ],
        colonnes: [
          {
            name: 'Afficher colonne Vendeur ',
            key: 'COTHACV',
            checked: false
          },
          { name: 'Afficher colonne Numéro', key: 'COTHACN', checked: false },
          { name: 'Afficher colonne Réference', key: 'COTHACREF', checked: false },
          {
            name: 'Afficher colonne Famille',
            key: 'COTHACF',
            checked: false
          },
          { name: 'Afficher colonne Type', key: 'COTHACT', checked: false },
          {
            name: 'Afficher colonne Date de création',
            key: 'COTHACDDC',
            checked: false
          },
          {
            name: 'Afficher colonne Date de création réelle',
            key: 'COTHACDDCR',
            checked: false
          },
          {
            name: 'Afficher colonne Acheteur',
            key: 'COTHACA',
            checked: false
          },
          { name: 'Afficher colonne Etat', key: 'COTHACE', checked: false },
          {
            name: 'Afficher colonne Date de paiement',
            key: 'COTHACDDP',
            checked: false
          },
          {
            name: 'Afficher colonne Catégorie',
            key: 'COTHAC',
            checked: false
          },
          {
            name: 'Afficher colonne NET HT',
            key: 'COTHACNETHT',
            checked: false
          },
          {
            name: 'Afficher colonne TVA',
            key: 'COTHACTVA',
            checked: false
          },
          {
            name: 'Afficher colonne Total TTC',
            key: 'COTHACTTC',
            checked: false
          },
          {
            name: 'Afficher colonne Comptabilisé',
            key: 'COTHACCOM',
            checked: false
          },
          {
            name: 'Afficher colonne Numéro de lot',
            key: 'COTHACNL',
            checked: false
          },
          {
            name: 'Afficher colonne CREE PAR',
            key: 'COTHACCP',
            checked: false
          },
          {
            name: 'Afficher colonne Historique',
            key: 'COTHACH',
            checked: false
          }
        ],
        fonctionalite: [
          { name: 'Télecharger ZIP', key: 'COTHTZIP', checked: false },
          { name: 'Télecharger xls', key: 'COTHTXLS', checked: false },

          { name: 'Télecharger Facture', key: 'COTHTFACT', checked: false },
          { name: 'Télecharger Facture Réference', key: 'COTHTFACTREF', checked: false },
          { name: 'Visualiser Facture', key: 'COTHTVF', checked: false },
          { name: 'Visualiser Facture Réference', key: 'COTHTVFREF', checked: false },

          { name: 'Dupliquer Facture', key: 'COTHTDF', checked: false },
          {
            name: 'Télecharger Facture avoir',
            key: 'COTHTTFA',
            checked: false
          },
          {
            name: 'Modifier la colonne Comptabilisé',
            key: 'COTHMLCC',
            checked: false
          }
        ],
        interfaces: []
      },
      // //Export Globale CDG
      // {
      //   name: 'Export globale XLS',
      //   key: 'EGXLSCDG',
      //   ,
      //   interfaces: []
      // },
      //Gestion des commerciaux
      {
        name: 'Gestion des commerciaux',
        key: 'GDCTH',
        checked: false,
        interfaces: [],
        filters: [],
        colonnes: [],
        fonctionalite: []
      },
      //Gestion des régies
      // {
      //   name: 'Gestion des régies',
      //   key: 'GDRTH',
      //   checked: false,
      //   autorisations: null,
      //   interfaces: [],
      //   filters: [],
      //   colonnes: [],
      //   fonctionalite: []
      // }
    ];
  }
  checkKeys() {
    let key = [];
    this.list.forEach(e => {
      key.push(e.key);
      if (e.interfaces.length) {
        e.interfaces.forEach(i => {
          key.push(i.key);

          i.permissions.forEach(pp => {
            key.push(pp.key);
          });
        });
      }
      if (e.permissions.length) {
        e.permissions.forEach(p => {
          key.push(p.key);
        });
      }
    });
    function find_duplicate_in_array(arra1) {
      var object = {};
      var result = [];

      arra1.forEach(function (item) {
        if (!object[item]) object[item] = 0;
        object[item] += 1;
      });

      for (var prop in object) {
        if (object[prop] >= 2) {
          result.push(prop);
        }
      }

      return result;
    }
    let duplique_table = find_duplicate_in_array(key);
    return duplique_table;
  }

  static create() {
    return new permissionModel();
  }
}
