<template>
  <div class="visibility-permission  style-box-setting">
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter">
          <v-progress-circular
            class="ml-2"
            v-if="loadingAdd"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </div>
        <div class="left-block-filter">
          <div class="search-input">
            <search
              :value="search"
              @changeSearchValue="debounceInput"
              hiddenContent="true"
              placeHolder="Rechercher par utilisateur"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="blocks">
        <div class="permission-hearder">
          <div class="title">Utilisateurs</div>
          <div class="list-interface fix-height-slide">
            <v-list class="list-category">
              <v-list-item-group>
                <v-list-item
                  button
                  v-for="user in Allusers"
                  :key="user.id"
                  @click.prevent="handleClickUser(user)"
                  class="custom-list-group custom-list-group-vue"
                >
                  <v-list-item-icon
                    v-if="
                      selectedUser && selectedUser.full_name == user.full_name
                    "
                  >
                    <font-awesome-icon class="color-cdg" icon="check" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="capitalize">
                        {{ user.full_name }}</span
                      ></v-list-item-title
                    >
                  </v-list-item-content></v-list-item
                ></v-list-item-group
              ></v-list
            >
          </div>
        </div>
        <div class="permission-hearder" v-if="selectedUser">
          <div class="permission-user" v-if="selectedUser">
            <div class="title">Interfaces</div>

            <div class="list-interface fix-height-slide">
              <v-list class="list-category">
                <v-list-item-group>
                  <v-list-item
                    button
                    v-for="permission in getListePermission"
                    :key="permission.id"
                    class="custom-list-group custom-list-group-vue"
                  >
                    <v-list-item-content>
                      <v-row class="align-center">
                        <v-col cols="2">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            v-model="permission.checked"
                            color="#704ad1"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            @change="assignPermissionInterface(permission)"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="8"
                          @click="handleClickInterface(permission)"
                        >
                          <span class="capitalize"> {{ permission.name }}</span>
                        </v-col>
                        <v-col cols="2">
                          <font-awesome-icon
                            class="color-cdg"
                            icon="plus"
                            v-if="
                              permission &&
                                ((permission.filters &&
                                  permission.filters.length != 0) ||
                                  (permission.fonctionalite &&
                                    permission.fonctionalite.length != 0) ||
                                  (permission.colonnes &&
                                    permission.colonnes.length != 0) ||
                                  (permission.interfaces &&
                                    permission.interfaces.length != 0))
                            "
                          />
                        </v-col>
                      </v-row> </v-list-item-content></v-list-item></v-list-item-group
              ></v-list>
            </div>
          </div>
        </div>
        <div
          class="permission-hearder-autorisations"
          v-if="
            selectedPage &&
              selectedPage &&
              (selectedPage.filters.length != 0 ||
                selectedPage.colonnes.length != 0 ||
                selectedPage.fonctionalite.length != 0)
          "
        >
          <div class="permission-user" v-if="selectedPage">
            <div class="title">
              Autorisations
              <div v-if="loading" class="chargement-message-calender">
                Chargement en cours...
              </div>
            </div>
            <div class="list-interface fix-height-slide">
              <div class="autorisation-permission custom-expansion">
                <!-- <div class="accordion" role="tablist"> -->
                <v-expansion-panels>
                  <!-- Filters  -->
                  <v-expansion-panel
                    v-if="
                      selectedPage &&
                        selectedPage.filters &&
                        selectedPage.filters.length
                    "
                  >
                    <v-expansion-panel-header :disable-icon-rotate="true">
                      <div class="container d-flex">
                        <p class="text-color">Filters</p>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-if="selectedPage.filters.length > 0"
                    >
                      <v-row class="row-style">
                        <v-col cols="1">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :value="computedChecked(selectedPage.filters)"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            @change="
                              checkAllPermissions($event, selectedPage.filters)
                            "
                            :checked="computedChecked(selectedPage.filters)"
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="11">Tous</v-col>
                      </v-row>

                      <v-row
                        class="row-style"
                        v-for="filter in selectedPage.filters"
                        :key="filter.key"
                      >
                        <v-col cols="1">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :id="'checkbox-group-1Tous' + filter.key"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            v-model="filter.checked"
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="11">
                          {{ filter.name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            text
                            @click.prevent.stop="
                              assignPermission(selectedPage.filters, 'filter')
                            "
                            title="Enregistrer"
                            :loading="loadingFilter"
                            :disabled="loadingFilter"
                            class="btn-action-permession"
                          >
                            Enregistrer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content></v-expansion-panel
                  >
                </v-expansion-panels>
                <!-- End Filter  -->
                <!-- Colonnes  -->

                <v-expansion-panels>
                  <v-expansion-panel
                    v-if="
                      selectedPage &&
                        selectedPage.colonnes &&
                        selectedPage.colonnes.length
                    "
                  >
                    <v-expansion-panel-header :disable-icon-rotate="true">
                      <div class="container d-flex">
                        <p class="text-color">Colonnes</p>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-if="selectedPage.colonnes.length > 0"
                    >
                      <v-row class="row-style">
                        <v-col cols="1">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            :checked="computedChecked(selectedPage.colonnes)"
                            @change="
                              checkAllPermissions($event, selectedPage.colonnes)
                            "
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="11">Tous</v-col>
                      </v-row>

                      <v-row
                        class="row-style"
                        v-for="colonne in selectedPage.colonnes"
                        :key="colonne.key"
                      >
                        <v-col cols="1">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :id="'checkbox-group-1Tous' + colonne.key"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            v-model="colonne.checked"
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="11">
                          {{ colonne.name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            text
                            @click.prevent.stop="
                              assignPermission(selectedPage.colonnes, 'colonne')
                            "
                            title="Enregistrer"
                            :loading="loadingColonne"
                            :disabled="loadingColonne"
                            class="btn-action-permession"
                          >
                            Enregistrer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content></v-expansion-panel
                  >
                  <!-- End colonnes  -->
                </v-expansion-panels>
                <!-- Fonctionnalite  -->

                <v-expansion-panels>
                  <v-expansion-panel
                    v-if="
                      selectedPage &&
                        selectedPage.fonctionalite &&
                        selectedPage.fonctionalite.length
                    "
                  >
                    <v-expansion-panel-header :disable-icon-rotate="true">
                      <div class="container d-flex">
                        <p class="text-color">Fonctionnalités</p>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-if="selectedPage.fonctionalite.length > 0"
                    >
                      <v-row class="row-style">
                        <v-col cols="1">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            :checked="
                              computedChecked(selectedPage.fonctionalite)
                            "
                            @change="
                              checkAllPermissions(
                                $event,
                                selectedPage.fonctionalite
                              )
                            "
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="11">Tous</v-col>
                      </v-row>

                      <v-row
                        class="row-style"
                        v-for="fonction in selectedPage.fonctionalite"
                        :key="fonction.key"
                      >
                        <v-col cols="1">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :id="'checkbox-group' + fonction.key"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            v-model="fonction.checked"
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="11">
                          {{ fonction.name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            text
                            @click.prevent.stop="
                              assignPermission(
                                selectedPage.fonctionalite,
                                'fonction'
                              )
                            "
                            title="Enregistrer"
                            :loading="loadingFonction"
                            :disabled="loadingFonction"
                            class="btn-action-permession"
                          >
                            Enregistrer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content></v-expansion-panel
                  >
                </v-expansion-panels>
                <!-- End Fonctionnalite  -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="permission-hearder"
          v-if="selectedPage && selectedPage.interfaces.length != 0"
        >
          <div class="permission-user" v-if="selectedPage">
            <div class="title">Sous Interfaces</div>
            <div class="fix-height-slide">
              <v-list class="list-category">
                <v-list-item-group>
                  <v-list-item
                    v-for="inter in selectedPage.interfaces"
                    :key="inter.key"
                    class="custom-list-group custom-list-group-vue"
                  >
                    <v-list-item-content>
                      <v-row class="align-center"></v-row
                    ></v-list-item-content>
                    <v-col cols="2">
                      <v-checkbox
                        label=""
                        class="checked-global"
                        v-model="inter.checked"
                        color="#704ad1"
                        :value="true"
                        :true-value="true"
                        :false-value="false"
                        hide-details
                        v-if="checkPermission('GPHTAPUS')"
                        @change="assignPermissionInterface(inter)"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="8" @click="handleClickSousInterface(inter)">
                      <span class="capitalize"> {{ inter.name }}</span>
                    </v-col>
                    <v-col cols="2">
                      <font-awesome-icon
                        class="color-cdg"
                        icon="plus"
                        v-if="
                          inter &&
                            ((inter.filters && inter.filters.length != 0) ||
                              (inter.colonnes && inter.colonnes.length != 0) ||
                              (inter.fonctionalite &&
                                inter.fonctionalite.length != 0))
                        "
                      />
                    </v-col> </v-list-item></v-list-item-group
              ></v-list>
            </div>
          </div>
        </div>
        <div
          class="permission-hearder"
          v-if="
            selectedSousInterface &&
              (selectedSousInterface.filters.length != 0 ||
                selectedSousInterface.colonnes.length != 0 ||
                selectedSousInterface.fonctionalite.length != 0)
          "
        >
          <div class="permission-user" v-if="selectedPage">
            <div class="title">
              Autorisations
              <div v-if="loading" class="chargement-message-calender">
                Chargement en cours...
              </div>
            </div>
            <div class="list-interface fix-height-slide">
              <div class="autorisation-permission custom-expansion">
                <v-expansion-panels>
                  <!-- Filters  -->
                  <v-expansion-panel
                    v-if="
                      selectedSousInterface &&
                        selectedSousInterface.filters &&
                        selectedSousInterface.filters.length
                    "
                  >
                    <v-expansion-panel-header :disable-icon-rotate="true">
                      <div class="container d-flex">
                        <p class="text-color">Filters</p>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-if="selectedSousInterface.filters.length > 0"
                    >
                      <v-row class="row-style">
                        <v-col cols="2">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            @change="
                              checkAllPermissions(
                                $event,
                                selectedSousInterface.filters
                              )
                            "
                            :checked="
                              computedChecked(selectedSousInterface.filters)
                            "
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="10">Tous</v-col>
                      </v-row>

                      <v-row
                        class="row-style"
                        v-for="filter in selectedSousInterface.filters"
                        :key="filter.key"
                      >
                        <v-col cols="2">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :id="'checkbox-group-1Tous' + filter.key"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            v-model="filter.checked"
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="10">
                          {{ filter.name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            text
                            @click.prevent.stop="
                              assignPermission(
                                selectedSousInterface.filters,
                                'filter'
                              )
                            "
                            title="Enregistrer"
                            :loading="loadingFilter"
                            :disabled="loadingFilter"
                            class="btn-action-permession"
                          >
                            Enregistrer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content></v-expansion-panel
                  >
                </v-expansion-panels>
                <!-- End Filter  -->
                <!-- Colonnes  -->

                <v-expansion-panels>
                  <v-expansion-panel
                    v-if="
                      selectedSousInterface &&
                        selectedSousInterface.colonnes &&
                        selectedSousInterface.colonnes.length
                    "
                  >
                    <v-expansion-panel-header :disable-icon-rotate="true">
                      <div class="container d-flex">
                        <p class="text-color">Colonnes</p>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-if="selectedSousInterface.colonnes.length > 0"
                    >
                      <v-row class="row-style">
                        <v-col cols="2">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            :checked="
                              computedChecked(selectedSousInterface.colonnes)
                            "
                            @change="
                              checkAllPermissions(
                                $event,
                                selectedSousInterface.colonnes
                              )
                            "
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="10">Tous</v-col>
                      </v-row>

                      <v-row
                        class="row-style"
                        v-for="colonne in selectedSousInterface.colonnes"
                        :key="colonne.key"
                      >
                        <v-col cols="2">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :id="'checkbox-group-1Tous' + colonne.key"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            v-model="colonne.checked"
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="10">
                          {{ colonne.name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            text
                            @click.prevent.stop="
                              assignPermission(
                                selectedSousInterface.colonnes,
                                'colonne'
                              )
                            "
                            title="Enregistrer"
                            :loading="loadingColonne"
                            :disabled="loadingColonne"
                            class="btn-action-permession"
                          >
                            Enregistrer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content></v-expansion-panel
                  >
                  <!-- End colonnes  -->
                </v-expansion-panels>
                <!-- Fonctionnalite  -->

                <v-expansion-panels>
                  <v-expansion-panel
                    v-if="
                      selectedSousInterface &&
                        selectedSousInterface.fonctionalite &&
                        selectedSousInterface.fonctionalite.length
                    "
                  >
                    <v-expansion-panel-header :disable-icon-rotate="true">
                      <div class="container d-flex">
                        <p class="text-color">Fonctionnalités</p>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-if="selectedSousInterface.fonctionalite.length > 0"
                    >
                      <v-row class="row-style">
                        <v-col cols="2">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            :checked="
                              computedChecked(
                                selectedSousInterface.fonctionalite
                              )
                            "
                            @change="
                              checkAllPermissions(
                                $event,
                                selectedSousInterface.fonctionalite
                              )
                            "
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="10">Tous</v-col>
                      </v-row>

                      <v-row
                        class="row-style"
                        v-for="fonction in selectedSousInterface.fonctionalite"
                        :key="fonction.key"
                      >
                        <v-col cols="2">
                          <v-checkbox
                            label=""
                            class="checked-global"
                            color="#704ad1"
                            :id="'checkbox-group' + fonction.key"
                            :value="true"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                            v-model="fonction.checked"
                            v-if="checkPermission('GPHTAPUS')"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="10">
                          {{ fonction.name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            text
                            @click.prevent.stop="
                              assignPermission(
                                selectedSousInterface.fonctionalite,
                                'fonction'
                              )
                            "
                            title="Enregistrer"
                            :loading="loadingFonction"
                            :disabled="loadingFonction"
                            class="btn-action-permession"
                          >
                            Enregistrer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content></v-expansion-panel
                  >
                </v-expansion-panels>
                <!-- End Fonctionnalite  -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissionModel from '../../../models/permisionModel';
export default {
  data() {
    return {
      loadingFilter: false,
      loadingColonne: false,
      loadingFonction: false,
      selectedUser: null,
      selectedPage: null,
      selectedSousInterface: null,
      interfaceAutorisation: [],
      sousInterfaceAutorisation: [],
      getListePermission: [],
      search: null,
      loading: false
    };
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  methods: {
    ...mapActions([
      'getAllUsers',
      'assignAutorisationToUser',
      'removeAutorisationToUser'
    ]),
    checkAllPermissions(e, permissions) {
      permissions.map(element => {
        element.checked = e;
      });
    },
    debounceInput: _.debounce(function(e) {
      this.search = e;
      let payload = {
        search: this.search
      };
      this.getAllUsers(payload);
    }, 500),
    async assignPermissionInterface(permission) {
      if (permission.checked == true) {
        let bodyFormData = new FormData();
        bodyFormData.append('user', this.selectedUser.id);
        bodyFormData.append('assigned[' + 0 + '][key]', permission.key);
        bodyFormData.append('assigned[' + 0 + '][name]', permission.name);
        let payload = {
          assigned: [permission],
          bodyFormData: bodyFormData,
          user: this.selectedUser.id
        };
        const response = await this.assignAutorisationToUser(payload);
        if (response.success) {
        }
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append('user', this.selectedUser.id);
        bodyFormData.append('deleted[' + 0 + '][key]', permission.key);
        bodyFormData.append('deleted[' + 0 + '][name]', permission.name);
        let payload = {
          deleted: [permission],
          bodyFormData: bodyFormData,
          user: this.selectedUser.id
        };
        const response = await this.removeAutorisationToUser(payload);
        if (response.success) {
        }
      }
    },
    async assignPermission(permissions, type) {
      let assigned = [];
      let deleted = [];
      assigned = permissions.filter(item => item.checked == true);
      deleted = permissions.filter(item => item.checked == false);
      if (assigned.length != 0) {
        this.loadingFilter = type == 'filter' ? true : false;
        this.loadingColonne = type == 'colonne' ? true : false;
        this.loadingFonction = type == 'fonction' ? true : false;
        let bodyFormData = new FormData();
        bodyFormData.append('user', this.selectedUser.id);
        assigned.forEach((element, index) => {
          bodyFormData.append('assigned[' + index + '][key]', element.key);
          bodyFormData.append('assigned[' + index + '][name]', element.name);
        });
        let payload = {
          assigned: assigned,
          bodyFormData: bodyFormData,
          user: this.selectedUser.id
        };
        const response = await this.assignAutorisationToUser(payload);
        if (response.success) {
          this.loadingFilter = false;
          this.loadingFonction = false;
          this.loadingColonne = false;
        }
      }
      if (deleted.length != 0) {
        this.loadingFilter = type == 'filter' ? true : false;
        this.loadingColonne = type == 'colonne' ? true : false;
        this.loadingFonction = type == 'fonction' ? true : false;
        let bodyFormData = new FormData();
        bodyFormData.append('user', this.selectedUser.id);
        deleted.forEach((element, index) => {
          bodyFormData.append('deleted[' + index + '][key]', element.key);
          bodyFormData.append('deleted[' + index + '][name]', element.name);
        });
        let payload = {
          deleted: deleted,
          bodyFormData: bodyFormData,
          user: this.selectedUser.id
        };
        const response = await this.removeAutorisationToUser(payload);
        if (response.success) {
          this.loadingFilter = false;
          this.loadingFonction = false;
          this.loadingColonne = false;
        }
      }
    },
    handleClickUser(user) {
      this.getListePermission.map(item => {
        item.checked = false;
      });
      this.selectedUser = user;
      this.selectedUser.permissions.map(permission => {
        this.getListePermission.map(item => {
          if (item.key == permission) {
            item.checked = true;
          }
        });
      });
      this.selectedPage = null;
      this.selectedSousInterface = null;
      this.interfaceAutorisation = [];
      this.sousInterfaceAutorisation = [];
    },
    handleClickInterface(permission) {
      this.selectedPage = permission;
      if (this.selectedPage.colonnes.length != 0) {
        this.selectedPage.colonnes.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedPage.filters.length != 0) {
        this.selectedPage.filters.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedPage.interfaces.length != 0) {
        this.selectedPage.interfaces.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedPage.fonctionalite.length != 0) {
        this.selectedPage.fonctionalite.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedPage.colonnes.length != 0) {
        this.selectedUser.permissions.map(per => {
          this.selectedPage.colonnes.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedPage.filters.length != 0) {
        this.selectedUser.permissions.map(per => {
          this.selectedPage.filters.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedPage.fonctionalite.length != 0) {
        this.selectedUser.permissions.map(per => {
          this.selectedPage.fonctionalite.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedPage.interfaces.length != 0) {
        this.selectedUser.permissions.map(per => {
          this.selectedPage.interfaces.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      this.selectedSousInterface = null;
    },
    handleClickSousInterface(inter) {
      this.selectedSousInterface = inter;
      if (this.selectedSousInterface.colonnes.length != 0) {
        this.selectedSousInterface.colonnes.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedSousInterface.filters.length != 0) {
        this.selectedSousInterface.filters.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedSousInterface.fonctionalite.length != 0) {
        this.selectedSousInterface.fonctionalite.map(item => {
          item.checked = false;
        });
      }
      if (this.selectedSousInterface.filters.length != 0) {
        this.selectedUser.permissions.map(per => {
          this.selectedSousInterface.filters.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedSousInterface.fonctionalite.length != 0) {
        this.selectedUser.permissions.map(per => {
          this.selectedSousInterface.fonctionalite.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedSousInterface.colonnes.length != 0) {
        this.selectedUser.permissions.map(per => {
          this.selectedSousInterface.colonnes.map(item => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
    }
  },
  computed: {
    ...mapGetters(['Allusers', 'loadingAdd', 'checkPermission']),
    computedChecked() {
      return function(filters) {
        let tableCheck = filters.filter(item => item.checked == true);
        if (tableCheck.length == filters.length) {
          return true;
        }
        return false;
      };
    }
  },
  mounted() {
    this.getListePermission = permissionModel.create().list;
    this.getAllUsers({ search: this.search });
  }
};
</script>

<style lang="scss" scoped>
.visibility-permission {
  .box-content {
    padding-top: 0px !important;
  }

  .blocks {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5c7;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 0px;
  }
  .title {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #2a2a2a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
  }
  .custom-list-group {
    overflow-y: auto;
    // max-height: calc(100vh - 330px);
    // height: calc(100vh - 330px);
  }

  .permission-hearder {
    height: 100%;
    padding: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 0px;
    width: 25%;
    max-width: 25%;
    margin-right: 10px;
  }
  .permission-hearder-autorisations {
    height: 100%;
    padding: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 0px;
    width: 50%;
    max-width: 50%;
    margin-right: 10px;
  }
  .checked-global {
    margin-top: 0px;
  }
}
</style>
<style lang="scss">
.visibility-permission {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
  #info-wrap {
    overflow: hidden;
  }
  .description-column {
    width: 72%;
    float: left;
    padding-left: 4px;
    font-size: 11px;
  }
  .info-column {
    width: 10%;
    float: left;
  }
  .interface-permission {
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 0px;
    font-size: medium;
  }
  .autorisation-permission {
    padding: 3px;
    padding-left: 5px;
    margin-bottom: 9px;
    font-size: medium;
  }
  .fix-height-slide {
    display: block;
    overflow-y: scroll !important;
    height: calc(100vh - 286px);
    padding-right: 9px;
  }

  .fix-height-slide::-webkit-scrollbar {
    width: 8px;
  }
  .fix-height-slide::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  .fix-height-slide::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }
  .fix-height-slide::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
  .fix-height-slide-user {
    display: block;
    overflow-y: scroll !important;
    height: calc(100vh - 325px);
  }

  .fix-height-slide-user::-webkit-scrollbar {
    width: 8px;
  }

  .fix-height-slide-user::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  .fix-height-slide-user::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  .fix-height-slide-user::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
  .icon-color {
    color: #4d4bac;
  }
  .selected-role {
    cursor: pointer;
  }
  .btn-action-permession {
    color: rgb(112, 74, 209);
    caret-color: rgb(112, 74, 209);
    border: 1px solid;
  }
}
.custom-expansion {
  .v-expansion-panels {
    padding-top: 0px !important;
  }
  .text-color {
    margin-bottom: 0px !important;
    font-size: 13px;
    font-weight: 600;
  }
  .v-expansion-panel-header__icon {
    padding: 0px 14px !important;
  }
  .v-expansion-panel-header {
    height: 40px !important;
    min-height: 30px !important;
    padding: 5px !important;
  }
  .v-expansion-panel-content {
    font-size: 13px;
    .col-11,
    .col-10 {
      align-self: center;
    }
  }
}
</style>
