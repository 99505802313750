var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"visibility-permission style-box-setting"},[_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"card-block-filter-setting"},[_c('div',{staticClass:"right-block-filter"},[(_vm.loadingAdd)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","color":"#704ad1"}}):_vm._e()],1),_c('div',{staticClass:"left-block-filter"},[_c('div',{staticClass:"search-input"},[_c('search',{attrs:{"value":_vm.search,"hiddenContent":"true","placeHolder":"Rechercher par utilisateur"},on:{"changeSearchValue":_vm.debounceInput}})],1)])])]),_c('div',{},[_c('div',{staticClass:"blocks"},[_c('div',{staticClass:"permission-hearder"},[_c('div',{staticClass:"title"},[_vm._v("Utilisateurs")]),_c('div',{staticClass:"list-interface fix-height-slide"},[_c('v-list',{staticClass:"list-category"},[_c('v-list-item-group',_vm._l((_vm.Allusers),function(user){return _c('v-list-item',{key:user.id,staticClass:"custom-list-group custom-list-group-vue",attrs:{"button":""},on:{"click":function($event){$event.preventDefault();return _vm.handleClickUser(user)}}},[(
                    _vm.selectedUser && _vm.selectedUser.full_name == user.full_name
                  )?_c('v-list-item-icon',[_c('font-awesome-icon',{staticClass:"color-cdg",attrs:{"icon":"check"}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(user.full_name))])])],1)],1)}),1)],1)],1)]),(_vm.selectedUser)?_c('div',{staticClass:"permission-hearder"},[(_vm.selectedUser)?_c('div',{staticClass:"permission-user"},[_c('div',{staticClass:"title"},[_vm._v("Interfaces")]),_c('div',{staticClass:"list-interface fix-height-slide"},[_c('v-list',{staticClass:"list-category"},[_c('v-list-item-group',_vm._l((_vm.getListePermission),function(permission){return _c('v-list-item',{key:permission.id,staticClass:"custom-list-group custom-list-group-vue",attrs:{"button":""}},[_c('v-list-item-content',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","value":true,"true-value":true,"false-value":false,"hide-details":""},on:{"change":function($event){return _vm.assignPermissionInterface(permission)}},model:{value:(permission.checked),callback:function ($$v) {_vm.$set(permission, "checked", $$v)},expression:"permission.checked"}})],1),_c('v-col',{attrs:{"cols":"8"},on:{"click":function($event){return _vm.handleClickInterface(permission)}}},[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(permission.name))])]),_c('v-col',{attrs:{"cols":"2"}},[(
                            permission &&
                              ((permission.filters &&
                                permission.filters.length != 0) ||
                                (permission.fonctionalite &&
                                  permission.fonctionalite.length != 0) ||
                                (permission.colonnes &&
                                  permission.colonnes.length != 0) ||
                                (permission.interfaces &&
                                  permission.interfaces.length != 0))
                          )?_c('font-awesome-icon',{staticClass:"color-cdg",attrs:{"icon":"plus"}}):_vm._e()],1)],1)],1)],1)}),1)],1)],1)]):_vm._e()]):_vm._e(),(
          _vm.selectedPage &&
            _vm.selectedPage &&
            (_vm.selectedPage.filters.length != 0 ||
              _vm.selectedPage.colonnes.length != 0 ||
              _vm.selectedPage.fonctionalite.length != 0)
        )?_c('div',{staticClass:"permission-hearder-autorisations"},[(_vm.selectedPage)?_c('div',{staticClass:"permission-user"},[_c('div',{staticClass:"title"},[_vm._v(" Autorisations "),(_vm.loading)?_c('div',{staticClass:"chargement-message-calender"},[_vm._v(" Chargement en cours... ")]):_vm._e()]),_c('div',{staticClass:"list-interface fix-height-slide"},[_c('div',{staticClass:"autorisation-permission custom-expansion"},[_c('v-expansion-panels',[(
                    _vm.selectedPage &&
                      _vm.selectedPage.filters &&
                      _vm.selectedPage.filters.length
                  )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":true}},[_c('div',{staticClass:"container d-flex"},[_c('p',{staticClass:"text-color"},[_vm._v("Filters")])])]),(_vm.selectedPage.filters.length > 0)?_c('v-expansion-panel-content',[_c('v-row',{staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"1"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","value":_vm.computedChecked(_vm.selectedPage.filters),"true-value":true,"false-value":false,"hide-details":"","checked":_vm.computedChecked(_vm.selectedPage.filters)},on:{"change":function($event){return _vm.checkAllPermissions($event, _vm.selectedPage.filters)}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"11"}},[_vm._v("Tous")])],1),_vm._l((_vm.selectedPage.filters),function(filter){return _c('v-row',{key:filter.key,staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"1"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","id":'checkbox-group-1Tous' + filter.key,"value":true,"true-value":true,"false-value":false,"hide-details":""},model:{value:(filter.checked),callback:function ($$v) {_vm.$set(filter, "checked", $$v)},expression:"filter.checked"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(filter.name)+" ")])],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-action-permession",attrs:{"text":"","title":"Enregistrer","loading":_vm.loadingFilter,"disabled":_vm.loadingFilter},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.assignPermission(_vm.selectedPage.filters, 'filter')}}},[_vm._v(" Enregistrer ")])],1)],1)],2):_vm._e()],1):_vm._e()],1),_c('v-expansion-panels',[(
                    _vm.selectedPage &&
                      _vm.selectedPage.colonnes &&
                      _vm.selectedPage.colonnes.length
                  )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":true}},[_c('div',{staticClass:"container d-flex"},[_c('p',{staticClass:"text-color"},[_vm._v("Colonnes")])])]),(_vm.selectedPage.colonnes.length > 0)?_c('v-expansion-panel-content',[_c('v-row',{staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"1"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","value":true,"true-value":true,"false-value":false,"hide-details":"","checked":_vm.computedChecked(_vm.selectedPage.colonnes)},on:{"change":function($event){return _vm.checkAllPermissions($event, _vm.selectedPage.colonnes)}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"11"}},[_vm._v("Tous")])],1),_vm._l((_vm.selectedPage.colonnes),function(colonne){return _c('v-row',{key:colonne.key,staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"1"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","id":'checkbox-group-1Tous' + colonne.key,"value":true,"true-value":true,"false-value":false,"hide-details":""},model:{value:(colonne.checked),callback:function ($$v) {_vm.$set(colonne, "checked", $$v)},expression:"colonne.checked"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(colonne.name)+" ")])],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-action-permession",attrs:{"text":"","title":"Enregistrer","loading":_vm.loadingColonne,"disabled":_vm.loadingColonne},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.assignPermission(_vm.selectedPage.colonnes, 'colonne')}}},[_vm._v(" Enregistrer ")])],1)],1)],2):_vm._e()],1):_vm._e()],1),_c('v-expansion-panels',[(
                    _vm.selectedPage &&
                      _vm.selectedPage.fonctionalite &&
                      _vm.selectedPage.fonctionalite.length
                  )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":true}},[_c('div',{staticClass:"container d-flex"},[_c('p',{staticClass:"text-color"},[_vm._v("Fonctionnalités")])])]),(_vm.selectedPage.fonctionalite.length > 0)?_c('v-expansion-panel-content',[_c('v-row',{staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"1"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","value":true,"true-value":true,"false-value":false,"hide-details":"","checked":_vm.computedChecked(_vm.selectedPage.fonctionalite)},on:{"change":function($event){return _vm.checkAllPermissions(
                              $event,
                              _vm.selectedPage.fonctionalite
                            )}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"11"}},[_vm._v("Tous")])],1),_vm._l((_vm.selectedPage.fonctionalite),function(fonction){return _c('v-row',{key:fonction.key,staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"1"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","id":'checkbox-group' + fonction.key,"value":true,"true-value":true,"false-value":false,"hide-details":""},model:{value:(fonction.checked),callback:function ($$v) {_vm.$set(fonction, "checked", $$v)},expression:"fonction.checked"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(fonction.name)+" ")])],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-action-permession",attrs:{"text":"","title":"Enregistrer","loading":_vm.loadingFonction,"disabled":_vm.loadingFonction},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.assignPermission(
                              _vm.selectedPage.fonctionalite,
                              'fonction'
                            )}}},[_vm._v(" Enregistrer ")])],1)],1)],2):_vm._e()],1):_vm._e()],1)],1)])]):_vm._e()]):_vm._e(),(_vm.selectedPage && _vm.selectedPage.interfaces.length != 0)?_c('div',{staticClass:"permission-hearder"},[(_vm.selectedPage)?_c('div',{staticClass:"permission-user"},[_c('div',{staticClass:"title"},[_vm._v("Sous Interfaces")]),_c('div',{staticClass:"fix-height-slide"},[_c('v-list',{staticClass:"list-category"},[_c('v-list-item-group',_vm._l((_vm.selectedPage.interfaces),function(inter){return _c('v-list-item',{key:inter.key,staticClass:"custom-list-group custom-list-group-vue"},[_c('v-list-item-content',[_c('v-row',{staticClass:"align-center"})],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","value":true,"true-value":true,"false-value":false,"hide-details":""},on:{"change":function($event){return _vm.assignPermissionInterface(inter)}},model:{value:(inter.checked),callback:function ($$v) {_vm.$set(inter, "checked", $$v)},expression:"inter.checked"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"8"},on:{"click":function($event){return _vm.handleClickSousInterface(inter)}}},[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(inter.name))])]),_c('v-col',{attrs:{"cols":"2"}},[(
                        inter &&
                          ((inter.filters && inter.filters.length != 0) ||
                            (inter.colonnes && inter.colonnes.length != 0) ||
                            (inter.fonctionalite &&
                              inter.fonctionalite.length != 0))
                      )?_c('font-awesome-icon',{staticClass:"color-cdg",attrs:{"icon":"plus"}}):_vm._e()],1)],1)}),1)],1)],1)]):_vm._e()]):_vm._e(),(
          _vm.selectedSousInterface &&
            (_vm.selectedSousInterface.filters.length != 0 ||
              _vm.selectedSousInterface.colonnes.length != 0 ||
              _vm.selectedSousInterface.fonctionalite.length != 0)
        )?_c('div',{staticClass:"permission-hearder"},[(_vm.selectedPage)?_c('div',{staticClass:"permission-user"},[_c('div',{staticClass:"title"},[_vm._v(" Autorisations "),(_vm.loading)?_c('div',{staticClass:"chargement-message-calender"},[_vm._v(" Chargement en cours... ")]):_vm._e()]),_c('div',{staticClass:"list-interface fix-height-slide"},[_c('div',{staticClass:"autorisation-permission custom-expansion"},[_c('v-expansion-panels',[(
                    _vm.selectedSousInterface &&
                      _vm.selectedSousInterface.filters &&
                      _vm.selectedSousInterface.filters.length
                  )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":true}},[_c('div',{staticClass:"container d-flex"},[_c('p',{staticClass:"text-color"},[_vm._v("Filters")])])]),(_vm.selectedSousInterface.filters.length > 0)?_c('v-expansion-panel-content',[_c('v-row',{staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"2"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","value":true,"true-value":true,"false-value":false,"hide-details":"","checked":_vm.computedChecked(_vm.selectedSousInterface.filters)},on:{"change":function($event){return _vm.checkAllPermissions(
                              $event,
                              _vm.selectedSousInterface.filters
                            )}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_vm._v("Tous")])],1),_vm._l((_vm.selectedSousInterface.filters),function(filter){return _c('v-row',{key:filter.key,staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"2"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","id":'checkbox-group-1Tous' + filter.key,"value":true,"true-value":true,"false-value":false,"hide-details":""},model:{value:(filter.checked),callback:function ($$v) {_vm.$set(filter, "checked", $$v)},expression:"filter.checked"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(filter.name)+" ")])],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-action-permession",attrs:{"text":"","title":"Enregistrer","loading":_vm.loadingFilter,"disabled":_vm.loadingFilter},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.assignPermission(
                              _vm.selectedSousInterface.filters,
                              'filter'
                            )}}},[_vm._v(" Enregistrer ")])],1)],1)],2):_vm._e()],1):_vm._e()],1),_c('v-expansion-panels',[(
                    _vm.selectedSousInterface &&
                      _vm.selectedSousInterface.colonnes &&
                      _vm.selectedSousInterface.colonnes.length
                  )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":true}},[_c('div',{staticClass:"container d-flex"},[_c('p',{staticClass:"text-color"},[_vm._v("Colonnes")])])]),(_vm.selectedSousInterface.colonnes.length > 0)?_c('v-expansion-panel-content',[_c('v-row',{staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"2"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","value":true,"true-value":true,"false-value":false,"hide-details":"","checked":_vm.computedChecked(_vm.selectedSousInterface.colonnes)},on:{"change":function($event){return _vm.checkAllPermissions(
                              $event,
                              _vm.selectedSousInterface.colonnes
                            )}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_vm._v("Tous")])],1),_vm._l((_vm.selectedSousInterface.colonnes),function(colonne){return _c('v-row',{key:colonne.key,staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"2"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","id":'checkbox-group-1Tous' + colonne.key,"value":true,"true-value":true,"false-value":false,"hide-details":""},model:{value:(colonne.checked),callback:function ($$v) {_vm.$set(colonne, "checked", $$v)},expression:"colonne.checked"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(colonne.name)+" ")])],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-action-permession",attrs:{"text":"","title":"Enregistrer","loading":_vm.loadingColonne,"disabled":_vm.loadingColonne},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.assignPermission(
                              _vm.selectedSousInterface.colonnes,
                              'colonne'
                            )}}},[_vm._v(" Enregistrer ")])],1)],1)],2):_vm._e()],1):_vm._e()],1),_c('v-expansion-panels',[(
                    _vm.selectedSousInterface &&
                      _vm.selectedSousInterface.fonctionalite &&
                      _vm.selectedSousInterface.fonctionalite.length
                  )?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":true}},[_c('div',{staticClass:"container d-flex"},[_c('p',{staticClass:"text-color"},[_vm._v("Fonctionnalités")])])]),(_vm.selectedSousInterface.fonctionalite.length > 0)?_c('v-expansion-panel-content',[_c('v-row',{staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"2"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","value":true,"true-value":true,"false-value":false,"hide-details":"","checked":_vm.computedChecked(
                              _vm.selectedSousInterface.fonctionalite
                            )},on:{"change":function($event){return _vm.checkAllPermissions(
                              $event,
                              _vm.selectedSousInterface.fonctionalite
                            )}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_vm._v("Tous")])],1),_vm._l((_vm.selectedSousInterface.fonctionalite),function(fonction){return _c('v-row',{key:fonction.key,staticClass:"row-style"},[_c('v-col',{attrs:{"cols":"2"}},[(_vm.checkPermission('GPHTAPUS'))?_c('v-checkbox',{staticClass:"checked-global",attrs:{"label":"","color":"#704ad1","id":'checkbox-group' + fonction.key,"value":true,"true-value":true,"false-value":false,"hide-details":""},model:{value:(fonction.checked),callback:function ($$v) {_vm.$set(fonction, "checked", $$v)},expression:"fonction.checked"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(fonction.name)+" ")])],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-action-permession",attrs:{"text":"","title":"Enregistrer","loading":_vm.loadingFonction,"disabled":_vm.loadingFonction},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.assignPermission(
                              _vm.selectedSousInterface.fonctionalite,
                              'fonction'
                            )}}},[_vm._v(" Enregistrer ")])],1)],1)],2):_vm._e()],1):_vm._e()],1)],1)])]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }